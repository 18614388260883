import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, Link as LinkMui, TextField, Typography } from "@material-ui/core"
import {
	Visibility as ShowPassword,
	VisibilityOff as HiddenPassword,
	Close as CloseIcon
} from "@material-ui/icons"

import { pathName } from "@/services/history"
import { Divider } from "@/components"
import UserService from "@/services/UserService"
import Api from "@/services/api"
import { useCommonApi } from "@/services/api/commonApi"

import { formatEmail } from "@/utils/sanitization"
import CommonAuthContainer from "@/pages/Auth/components/Container"
import useValidation from "@/hooks/useValidation"
import { UserTypeEnum } from "@/protocols/UserProtocol"
import useCustomStyles from "@/styles/custom"
import { formatToOnlyNumberAndCommonLetter } from "@/utils/string"

type SignInDataProps = {
	email: string
	password: string
	newAccessCode?: string
}

const SignInStudent = () => {
	const commonApi = useCommonApi()

	const [accessCodeModal, setAccessCodeModal] = useState<boolean>(false)
	const [accessCodeLoading, setAccessCodeLoading] = useState<boolean>(false)

	const [visiblePassword, setVisiblePassword] = useState<boolean>(false)
	const [signInData, setSignInData] = useState<SignInDataProps>({} as SignInDataProps)
	const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)

	const {
		validation,
		parseError,
		triggerValidation,
		clearValidation
	} = useValidation()

	const customClasses = useCustomStyles()

	const handleClickShowPassword = () => {
		setVisiblePassword(!visiblePassword)
	}

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault()
	}

	const handleSignInDataChange = (type: keyof SignInDataProps, value: string) => {
		clearValidation(type)

		setSignInData((currentState) => ({
			...currentState,
			[type]: value
		}))
	}

	const toggleAccessCodeModal = () => {
		setAccessCodeModal(!accessCodeModal)
	}

	const submitSignInData = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		setLoadingSubmit(true)

		try {
			const { data } = await Api.post(`auth/signin/${UserTypeEnum.STUDENT}`, {
				...signInData,
				pathUrl: pathName[1]
			})

			const { token } = data
			UserService.login(token)

			await commonApi.loadUserData()
		} catch (error) {
			const errorData = parseError(error)

			if (errorData.errorName === "invalid_access_code") {
				toggleAccessCodeModal()
			}

			triggerValidation(error)
		}

		setLoadingSubmit(false)
	}

	const handleChangeCodeAccess = (value: string): void => {
		// Change spaces to => _ (Underline)
		let formattedAccessCode = value.replace(/\s/g, "_")

		formattedAccessCode = formatToOnlyNumberAndCommonLetter(
			formattedAccessCode.toUpperCase()
		)

		handleSignInDataChange("newAccessCode", formattedAccessCode)
	}

	const submitAccessCode = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		setAccessCodeLoading(true)

		try {
			await submitSignInData(event)

			toggleAccessCodeModal()
		} catch (error) {
			triggerValidation(error)
		}

		setAccessCodeLoading(false)
	}

	return (
		<CommonAuthContainer containSocialMedia>
			<Grid
				spacing={1}
				container
				justify="center"
				component="form"
				onSubmit={submitSignInData}
			>
				<Grid item xs={10}>
					<TextField
						id="input-email"
						label="Email"
						tabIndex={1}
						placeholder="seunome@seuemail.com"
						name="email"
						type="email"
						value={signInData.email}
						onChange={({ target }) =>
							handleSignInDataChange(
								"email",
								formatEmail(target.value)
							)}
						variant="outlined"
						fullWidth
						helperText={validation.email}
						error={Boolean(validation.email)}
					/>
				</Grid>

				<Grid item xs={10}>
					<TextField
						id="input-password"
						label="Senha"
						tabIndex={2}
						name="password"
						value={signInData.password}
						type={visiblePassword ? "text" : "password"}
						onChange={
							({ target }) => handleSignInDataChange(
								"password",
								target.value
							)
						}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="Tornar senha visível"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{visiblePassword ? <ShowPassword /> : <HiddenPassword />}
									</IconButton>
								</InputAdornment>
							)
						}}
						fullWidth
						variant="outlined"
						helperText={validation.password}
						error={Boolean(validation.password)}
					/>

					<Divider size={1} orientation="horizontal" />

					<Typography>
						Esqueceu sua senha? {" "}
						<LinkMui
							component={Link}
							to={"/recuperar-senha"}
							underline="none"
						>
						Clique aqui
						</LinkMui>
					</Typography>
				</Grid>

				<Divider size={3} orientation="horizontal" />

				<Grid item xs={10}>
					<Button
						tabIndex={-1}
						color="primary"
						type="submit"
						variant="contained"
						endIcon={
							loadingSubmit &&
							<CircularProgress size={16} color="inherit"/>
						}
						disabled={loadingSubmit}
						fullWidth
					>
						Acessar minha conta
					</Button>
				</Grid>
			</Grid>
			<Divider size={3} orientation="horizontal" />

			<Typography
				align="center"
			>
				Não possui uma conta? {" "}
				<LinkMui
					component={Link}
					to="/signup"
					color="inherit"
					underline="none"
				>
					Cadastre-se
				</LinkMui>
			</Typography>

			<Dialog
				open={accessCodeModal}
				onClose={toggleAccessCodeModal}
				PaperProps={{
					className: customClasses.paperDialog
				}}
				maxWidth="sm"
				fullWidth
			>
				<IconButton onClick={toggleAccessCodeModal} className={customClasses.closeIcon}>
					<CloseIcon />
				</IconButton>

				<DialogTitle>
					<Typography align="center" variant="h3" color="primary">
						Insira um novo código de acesso!
					</Typography>
				</DialogTitle>

				<Divider size={2} orientation="horizontal" />

				<DialogContent>
					<Typography align="center" variant="body2">
						O código que estava utilizando foi deletado, cadastre um novo...
					</Typography>

					<Divider size={2} orientation="horizontal" />

					<Grid
						container
						justify="center"
						alignContent="center"
						component="form"
						spacing={2}
						onSubmit={submitAccessCode}
					>
						<Grid item xs={12}>
							<TextField
								tabIndex={1}
								name="newAccessCode"
								label="Código de acesso"
								placeholder="Digite o código fornecido pela instituição"
								type="text"
								onChange={({ target }) => handleChangeCodeAccess(
									target.value
								)}
								value={signInData.newAccessCode}
								variant="outlined"
								fullWidth
								helperText={validation.newAccessCode}
								error={Boolean(validation.newAccessCode)}
							/>
						</Grid>

						<Grid item xs={12}>
							<Grid container justify="center" spacing={2}>
								<Grid item>
									<Button
										tabIndex={3}
										onClick={toggleAccessCodeModal}
									>
										Fechar
									</Button>
								</Grid>

								<Grid item>
									<Button
										type="submit"
										tabIndex={2}
										color="primary"
										variant="contained"
										disabled={accessCodeLoading}
										endIcon={
											accessCodeLoading &&
											<CircularProgress size={16} color="inherit"/>
										}
									>
										Validar
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

				</DialogContent>
			</Dialog>

		</CommonAuthContainer>
	)
}

export default SignInStudent
