import React, { Suspense } from "react"
import { Route, Router, Switch } from "react-router-dom"
import { AnimatePresence } from "framer-motion"

import { NotFound } from "@/pages"

import { SuspenseLoading } from "@/components"

import history, { pathName } from "@/services/history"
import Api from "@/services/api"
import useDidMount from "@/hooks/useDidMount"

import PublicRoutes from "@/routes/public"
import PrivateRoutes from "@/routes/private"
import UserService from "@/services/UserService"

export const onLoadLanding = async (pathUrl: string) => {
	try {
		if (pathUrl) {
			const { data } = await Api.get(
				`sponsor-config/landing-page/${pathUrl}`
			)

			return data
		} else {
			window.location.href = `${window.location.origin}/enem-vip`
		}
	} catch (error) {
		window.location.href = `${window.location.origin}/enem-vip`
	}
}

const PrivateComponent = () => {
	const getInitialData = async () => {
		const isLoggedIn = UserService.isAuthenticated()

		if (!isLoggedIn) {
			pathName[1] === "enem-vip" || pathName[1] === "" ? (
				history.push("/admin")
			) : (
				history.push("/signin")
			)
		}
	}

	useDidMount(() => {
		getInitialData()
	})

	return <PrivateRoutes />
}

const Routes = () => {
	useDidMount(async () => {
		await onLoadLanding(pathName[1])
	})

	return (
		<Router history={history}>
			<AnimatePresence>
				<Suspense fallback={<SuspenseLoading />}>
					<Switch>
						{PublicRoutes}

						<Route path="/" component={PrivateComponent} />
						<Route path="*" component={NotFound} />
					</Switch>
				</Suspense>
			</AnimatePresence>
		</Router>
	)
}

export default Routes
