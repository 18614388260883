import React from "react"
import {
	Chip
} from "@material-ui/core"
import {
	Check as CheckIcon,
	Error as ErrorIcon
} from "@material-ui/icons"
import colors from "@/styles/colors"

type ChipStatusProps = {
	isActive: boolean
}

const StatusChip: React.FC<ChipStatusProps> = (props: ChipStatusProps) => {
	const { isActive } = props

	return <Chip
		label={
			isActive
				? "Ativo"
				: "Inativo"
		}
		icon={
			isActive
				? <CheckIcon style={{ color: "inherit" }}/>
				: <ErrorIcon style={{ color: "inherit" }}/>
		}
		style={{
			backgroundColor: isActive
				? colors.palette.confirm
				: colors.palette.error,
			color: colors.grayScale[11],
			fontWeight: "bold"
		}}
	/>
}

export default StatusChip
