import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"

const useStyles = makeStyles({
	currentValueText: {
		
		fontSize: "18px",
		"& > b": {
			fontSize: "32px",
			color: colors.grayScale[1]
		}
	},
	cardContainer: {
		height: "100%",
		boxShadow: "0px 1px 1px 0px rgb(0 0 0 / 20%), 0px 1px 2px 0px rgb(0 0 0 / 14%), 0px -1px 6px 0px rgb(0 0 0 / 12%)"
	},
	footerText: {
		fontWeight: 500,
		fontSize: "calc(.95rem * 1.2)"
	}
})

export default useStyles
