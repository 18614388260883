import styled from "styled-components"

export const SidebarContainer = styled.section`
	background-color: ${({ backgroundColor }) => `${backgroundColor}`};

	a,
	.btn {
		color: ${({ color }) => `${color}`};
		:hover {
			color: ${({ color }) => `${color}`};
			opacity: 0.5;
		}
	}

	:hover {
		.app-sidebar--header .expand-sidebar-btn {
			background-color: ${({ backgroundColor }) => `${backgroundColor}`};
		}
	}
`
