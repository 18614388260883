import ApiService from "@/services/api"

export type CourseOfInterestOptionsProps = {
	baseCourseName: string
	baseCourseId: number
	urlPath: string
}

export type CityAndStateOptionsProps = {
	id: number
	urlPath: string
	cityState: string
}

class IntegrationService {
	async getCourseOptions (courseOfInterestSearch: string): Promise<CourseOfInterestOptionsProps[]> {
		try {
			const { data } = await ApiService
				.get("/beduka-integration/course/search", {
					params: {
						courseName: courseOfInterestSearch
					}
				})

			return data
		} catch (error) {
			throw new Error(error)
		}
	}

	async getCityOptions (cityAndStateSearch: string):
	Promise<CityAndStateOptionsProps[]> {
		try {
			const { data } = await ApiService.get("/beduka-integration/city/search", {
				params: {
					cityAndState: cityAndStateSearch
				}
			})

			return data
		} catch (error) {
			throw new Error(error)
		}
	}
}

export default new IntegrationService()
