import React from "react"
import { Provider } from "react-redux"
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core"
import { BrowserRouter } from "react-router-dom"

import themeMui from "@/styles/theme"

import configureStore from "@/config/configureStore"

import Routes from "@/routes"
import ScrollToTop from "@/utils/scrollToTop"

import { ThemeProvider } from "styled-components"

import "@/assets/base.scss"
import "@/styles/global.css"

const store = configureStore() as any

// Seta todos os dados do tema
const Theme = {
	backgroundPrimary: "red",
	image: "white"
}
const App = () => {
	return (
		<ThemeProvider theme={Theme}>
			<MuiThemeProvider theme={themeMui}>
				<Provider store={store}>
					<BrowserRouter>
						<ScrollToTop>
							<Routes />
						</ScrollToTop>
					</BrowserRouter>
				</Provider>
			</MuiThemeProvider>
		</ThemeProvider>
	)
}

export default App
