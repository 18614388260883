import React, { useState, useEffect } from "react"
import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@material-ui/core"
import { useSelector } from "react-redux"
import moment from "moment"
import {
	PersonAdd as PersonAddIcon,
	PersonAddOutlined as PersonAddOutlinedIcon,
	School as SchoolIcon,
	SchoolOutlined as SchoolOutlinedIcon
} from "@material-ui/icons"

import ApiService from "@/services/api"
import DashboardSkeleton from "@/skeletons/DashboardSkeleton"
import { RootStateProps } from "@/reducers"

import CardCompareValues from "@/pages/Dashboard/components/CardCompareValues"
import BasicPieChart from "@/components/Analytics/BasicPieChart"
import { Divider, Loading, Notification, Portlet } from "@/components"
import useCustomStyles from "@/styles/custom"

type UsersDataProps = {
	totalUsers: number
	userByDate?: {
		currentDate: number
		lastDate: number
		percentageCompare: number
	}
}

type SponsorDataProps = {
	totalSponsors: number
	sponsorByDate?: {
		currentDate: number
		lastDate: number
		percentageCompare: number
	}
}

type AllSponsorsProps = {
	id: number
	fantasyName: number
	socialName: string
}

type CountAccessCodeByUserProps = {
	accessCodeId: number
	accessCodeName: string
	countUser: number
}

type AccessCodeAnalyticRequestProps = {
	allSponsors: AllSponsorsProps[]
	countAccessCodeByUser: CountAccessCodeByUserProps[]
}

type AccessCodeAnalyticProps = {
	categories: string[]
	data: number[]
	allSponsors: AccessCodeAnalyticRequestProps["allSponsors"]
}

export const DashboardAdmin = () => {
	const customClasses = useCustomStyles()
	const { userInfo } = useSelector((state: RootStateProps) => state.UserConfig)

	const [loading, setLoading] = useState<boolean>(true)
	const [usersValues, setUsersValues] = useState<UsersDataProps>({
		totalUsers: 0
	})
	const [sponsorsValues, setSponsorsValues] = useState<SponsorDataProps>({
		totalSponsors: 0
	})
	const [accessCodeAnalytic, setAccessCodeAnalytic] = useState<AccessCodeAnalyticProps>({
		categories: [],
		data: [],
		allSponsors: []
	})
	const [selectedSponsorId, setSelectedSponsorId] = useState<number>()

	const getCompareValues = async () => {
		try {
			const startOfMonth = moment().startOf("month").format("YYYY-MM-DD")

			const endOfMonth = moment().endOf("month").format("YYYY-MM-DD")

			const startOfLastMonth = moment(startOfMonth)
				.subtract(1, "months")
				.endOf("month")
				.format("YYYY-MM-DD")

			const endOfLastMonth = moment(endOfMonth)
				.subtract(1, "months")
				.endOf("month")
				.format("YYYY-MM-DD")

			const { data } = await ApiService.get("/user/analytics", {
				params: {
					startDateInitialDate: startOfMonth,
					endDateInitialDate: endOfMonth,
					startDateLastDate: startOfLastMonth,
					endDateLastDate: endOfLastMonth
				}
			})

			setUsersValues(data.userData)
			setSponsorsValues(data?.sponsorData)
		} catch (error) {
			Notification.error({
				message: "Houve um erro inesperado!"
			})
		}
	}

	const getAccessCodeAnalytics = async (sponsorId?: number) => {
		try {
			const { data } = await ApiService.get("/access-code/analytics", {
				params: {
					sponsorId
				}
			})

			const {
				countAccessCodeByUser,
				allSponsors
			} = data as AccessCodeAnalyticRequestProps

			const codesName: string[] = []
			const countUser: number[] = []

			countAccessCodeByUser.forEach((item) => {
				codesName.push(item.accessCodeName)
				countUser.push(item.countUser)
			})

			setAccessCodeAnalytic({
				categories: codesName,
				data: countUser,
				allSponsors
			})
		} catch (error) {
			Notification.error({
				message: "Houve um erro inesperado!"
			})
		}
	}

	const handleSelectedSponsorId = async (sponsorId: string) => {
		const formatSponsorIdToNumber = sponsorId ? +sponsorId : undefined

		setSelectedSponsorId(formatSponsorIdToNumber)
		await getAccessCodeAnalytics(formatSponsorIdToNumber)
	}

	useEffect(() => {
		const fetchData = async () => {
			await getCompareValues()
			await getAccessCodeAnalytics()
		}
		fetchData()
		setLoading(false)
	}, [])

	return (
		<Loading
			loading={loading}
			customLoadingElement={<DashboardSkeleton />}
		>
			<Grid
				container
				spacing={2}
				justify="space-between"
			>
				<Grid
					item
					xs={12}
					sm={6}
				>
					<Portlet
						elevation={3}
					>
						<Grid container justify="space-between" spacing={2}>
							<Grid item xs={12} md={6}>
								<CardCompareValues
									icon={
										<PersonAddOutlinedIcon/>

									}
									title={"Estudantes - Mês"}
									dataValues={{
										lastValue:
											usersValues?.userByDate?.lastDate ||
											0,
										currentValue:
											usersValues?.userByDate
												?.currentDate || 0,
										percentageCompare:
											usersValues?.userByDate
												?.percentageCompare || 0,
										referenceTime: "Mês"
									}}
								/>
							</Grid>

							<Grid item xs={12} md={6}>
								<CardCompareValues
									icon={
										<PersonAddIcon/>
									}
									title={"Estudantes - Total"}
									dataValues={{
										currentValue: usersValues?.totalUsers,
										referenceTime: "Total"
									}}
								/>
							</Grid>

							{!!sponsorsValues?.sponsorByDate && (
								<Grid item xs={12} md={6}>
									<CardCompareValues
										icon={
											<SchoolOutlinedIcon/>
										}
										title={"Faculdades - Mês"}
										dataValues={{
											lastValue:
												sponsorsValues.sponsorByDate
													.lastDate,
											currentValue:
												sponsorsValues.sponsorByDate
													.currentDate,
											percentageCompare:
												sponsorsValues.sponsorByDate
													.percentageCompare,
											referenceTime: "Mês"
										}}
									/>
								</Grid>
							)}

							{!!sponsorsValues?.totalSponsors && (
								<Grid item xs={12} md={6}>
									<CardCompareValues
										icon={
											<SchoolIcon/>
										}
										title={"Faculdades - Total"}
										dataValues={{
											currentValue:
												sponsorsValues.totalSponsors,
											referenceTime: "Total"
										}}
									/>
								</Grid>
							)}
						</Grid>

					</Portlet>

				</Grid>

				<Grid
					item
					xs={12}
					sm={6}
				>
					<Portlet
						elevation={3}
					>
						<Grid container justify="center" spacing={2}>
							<Grid item xs={12}>
								{!!accessCodeAnalytic?.allSponsors?.length && (
									<FormControl
										variant="outlined"
										fullWidth
									>
										<InputLabel id="select-sponsor">
											Selecione a instituição
										</InputLabel>

										<Select
											id="select-sponsor-helper"
											label="Selecionar Instituição"
											labelId="select-sponsor"
											value={
												selectedSponsorId || null
											}
											onChange={({ target }) =>
												handleSelectedSponsorId(
													target.value as string
												)
											}
										>
											<MenuItem
												value=""
												disabled
											>
												Selecione uma opção
											</MenuItem>

											{
												!!accessCodeAnalytic?.allSponsors.length &&
												accessCodeAnalytic?.allSponsors.map((sponsor, index) => (
													<MenuItem
														key={index}
														value={sponsor?.id}
													>
														{sponsor?.fantasyName}
													</MenuItem>
												))
											}
										</Select>
									</FormControl>

								)}
							</Grid>

							<Grid item xs={12}>
								{
									userInfo.userType === "A" &&
										!selectedSponsorId
										? <Grid container justify="center">
											<Grid item>
												<Divider size={2} orientation="horizontal" />

												<Typography
													align="center"
													variant="caption"
													className={customClasses.notFoundText}
												>
													Selecione uma instituição
												</Typography>

												<Divider size={2} orientation="horizontal" />
											</Grid>
										</Grid>
										: <Grid container justify="center">
											<Grid item>
												<BasicPieChart
													categories={
												accessCodeAnalytic?.categories || []
													}
													data={accessCodeAnalytic?.data || []}
												/>
											</Grid>
										</Grid>
								}
							</Grid>
						</Grid>

					</Portlet>
				</Grid>
			</Grid>
		</Loading>
	)
}

export default DashboardAdmin
