import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

import {
	Link as LinkMui,
	Grid,
	IconButton,
	Button,
	InputAdornment,
	TextField,
	Tooltip,
	Popover,
	Typography,
	Container,
	AccordionSummary,
	AccordionDetails
} from "@material-ui/core"
import {
	FormatColorFill as ColorIcon,
	ArrowBackIos as ArrowBackIcon,
	SettingsOverscan as FullWidthIcon,
	ExpandMore as ExpandMoreIcon,
	Image as ImageIcon,
	TextFields as TextFieldsIcon,
	Contacts as ContactsIcon,
	Language as WebsiteIcon,
	Wallpaper as WallpaperIcon,
	Panorama as PanoramaIcon,
	Share as ShareIcon,
	CallToAction as FooterIcon,
	AddBox as AddBoxIcon
} from "@material-ui/icons"
import clsx from "clsx"

import { DropzoneImage, Loading, Notification } from "@/components"
import ColorPicker from "@/pages/LandingPage/Editor/components/ColorPicker"
import ApiService from "@/services/api"
import { RootStateProps } from "@/reducers"

import { formatPhoneNumber } from "@/utils/mask"
import { cleanObjectValues, formatEmail } from "@/utils/sanitization"
import useValidation from "@/hooks/useValidation"

import LandingPage, { LandingDataProps } from "@/pages/LandingPage"

import useCustomStyles from "@/styles/custom"
import useStyles, { Accordion } from "./styles"
import useDidMount from "@/hooks/useDidMount"

const EditorLandingPage = () => {
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [dataLanding, setDataLanding] = useState<LandingDataProps>(
		{} as LandingDataProps
	)
	const [editDataLanding, setEditDataLanding] = useState<
	Partial<LandingDataProps>
	>({} as LandingDataProps)
	const [accordionOpen, setAccordionOpen] = useState<string | null>(null)
	const [isConsoleVisible, setIsConsoleVisible] = useState(true)
	const [deviceIs, setDeviceIs] = useState<"mobile" | "desktop">("desktop")

	const [popoverInputType, setInputPopoverType] = useState<string | null>(
		null
	)
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	)

	const { sponsorConfig, userInfo } = useSelector(
		(state: RootStateProps) => state.UserConfig
	)

	const { clearValidation, triggerValidation, validation } = useValidation()

	const classes = useStyles({
		fullWidth: isConsoleVisible,
		deviceIs: deviceIs,
		titleColorPicker: dataLanding?.titleLandingColor,
		subtitleColorPicker: dataLanding?.subtitleLandingColor,
		buttonColorPicker: dataLanding?.btnLandingColor
	})
	const customClasses = useCustomStyles()

	const handleChangeView = (viewType: "desktop" | "mobile" | "fullWidth") => {
		const options = {
			desktop: {
				changeDevice: "desktop",
				changeViewEditor: isConsoleVisible
			},
			mobile: {
				changeDevice: "mobile",
				changeViewEditor: isConsoleVisible
			},
			fullWidth: {
				changeDevice: deviceIs,
				changeViewEditor: !isConsoleVisible
			}
		}
		setDeviceIs(options[viewType].changeDevice as "mobile" | "desktop")
		setIsConsoleVisible(options[viewType].changeViewEditor)
	}

	const handlePopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)
		setInputPopoverType(event.currentTarget.name)
	}

	const handlePopoverClose = () => {
		setAnchorEl(null)
		setInputPopoverType(null)
	}

	const toggleAccordion = (tab: string | null) => {
		tab === accordionOpen ? setAccordionOpen(null) : setAccordionOpen(tab)
	}

	const handleChange = (
		value: string | null,
		type: keyof LandingDataProps
	) => {
		clearValidation(type)

		const newData = {
			...editDataLanding,
			[type]: value
		}

		setEditDataLanding(newData)
	}

	const handleChangeColor =
		(value: keyof LandingDataProps) => (hex: string) => {
			handleChange(hex, value)
		}

	const onLoadLandingPage = async () => {
		setIsLoading(true)

		try {
			const { data } = await ApiService.get(
				`sponsor-config/landing-page/${sponsorConfig.pathUrl}`
			)
			setDataLanding(data)
			setEditDataLanding(cleanObjectValues(data))
		} catch (error) {
			Notification.error({
				message: "Erro ao carregar a página, tente novamente!"
			})
		}

		setIsLoading(false)
	}

	const saveNewLandingPage = async () => {
		try {
			await ApiService.patch("/sponsor-config", editDataLanding)

			Notification.success({
				message: "Alterações realizadas com sucesso!"
			})
		} catch (error) {
			triggerValidation(error)

			Notification.error({
				message:
					"Alguma informação está incorreta, confira os campos..."
			})
		}
	}

	const handleChangeImage = async (
		image: File,
		formData: FormData,
		type: keyof LandingDataProps
	) => {
		await ApiService.post(`sponsor-config/upload/${type}`, formData)
			.then(({ data }) => {
				handleChange(data, type)
			})
			.catch(() => {
				Notification.error({
					message: "Erro ao fazer o upload da imagem"
				})
			})
	}

	const handleDeleteImage = async (type: keyof LandingDataProps) => {
		handleChange(null, type)
	}

	useDidMount(() => {
		onLoadLandingPage()
	})

	return (
		<Container
			component="section"
			maxWidth={false}
			disableGutters
			className={classes.editorContainer}
		>
			<Grid container>
				<Grid item xs={12} className={classes.headerContainer}>
					<Grid
						container
						alignItems="center"
						alignContent="center"
						style={{
							height: "100%"
						}}
					>
						<Grid item xs={4}>
							<LinkMui
								component={Link}
								to="/dashboard"
								underline="none"
							>
								<Grid
									container
									alignContent="center"
									wrap="nowrap"
								>
									<Grid item style={{ marginLeft: "6px" }}>
										<ArrowBackIcon color="primary" />
									</Grid>

									<Grid item>
										<Typography color="primary">
											Dashboard
										</Typography>
									</Grid>
								</Grid>
							</LinkMui>
						</Grid>
						<Grid item xs={4}>
							<Grid container justify="center">
								{/* <Grid item>
									<IconButton
										onClick={() =>
											handleChangeView("mobile")
										}
										disabled={deviceIs === "mobile"}
									>
										<SmartPhoneIcon
											className={
												clsx(
													deviceIs === "mobile" &&
													classes.activeButtonHeader
												)
											}
										/>
									</IconButton>
								</Grid>

								<Grid item>
									<IconButton
										onClick={() =>
											handleChangeView("desktop")
										}
										disabled={deviceIs === "desktop"}
									>
										<ComputerIcon
											className={
												clsx(
													deviceIs === "desktop" &&
													classes.activeButtonHeader
												)
											}
										/>
									</IconButton>
								</Grid> */}

								<Grid item>
									<IconButton
										onClick={() =>
											handleChangeView("fullWidth")
										}
									>
										<FullWidthIcon
											className={clsx(
												!isConsoleVisible &&
													classes.activeButtonHeader
											)}
										/>
									</IconButton>
								</Grid>
							</Grid>
						</Grid>
						<Grid
							item
							xs={4}
							className={classes.saveButtonContainer}
						>
							<Button
								onClick={saveNewLandingPage}
								color="inherit"
								className={clsx(
									customClasses.confirmButton,
									classes.saveButton
								)}
							>
								SALVAR
							</Button>
						</Grid>
					</Grid>
				</Grid>

				<Loading loading={isLoading}>
					<Grid item xs={12}>
						<Grid container>
							{isConsoleVisible && (
								<Grid
									item
									xs={12}
									sm={4}
									className={clsx(
										classes.consoleEditContainer,
										customClasses.scrollBar
									)}
								>
									<Accordion
										square
										expanded={accordionOpen === "Logomarca"}
										onChange={() =>
											toggleAccordion("Logomarca")
										}
									>
										<AccordionSummary
											expandIcon={
												<ExpandMoreIcon color="primary" />
											}
											aria-controls="trademark-accordion-content"
											id="trademark-accordion-header"
										>
											<Grid container spacing={2}>
												<Grid item>
													<ImageIcon color="primary" />
												</Grid>

												<Grid item>
													<Typography
														variant="h3"
														color="primary"
														className={
															classes.titleAccordion
														}
													>
														Logomarca
													</Typography>
												</Grid>
											</Grid>
										</AccordionSummary>
										<AccordionDetails>
											<Grid container justify="center">
												<Grid item>
													<DropzoneImage
														onDelete={
															handleDeleteImage
														}
														onChange={
															handleChangeImage
														}
														dataImg={
															editDataLanding?.trademarkUrlLanding ||
															null
														}
														typeImage="trademarkUrlLanding"
													/>
												</Grid>
											</Grid>
										</AccordionDetails>
									</Accordion>

									<Accordion
										square
										expanded={
											accordionOpen ===
											"Título e Subtítulo"
										}
										onChange={() =>
											toggleAccordion(
												"Título e Subtítulo"
											)
										}
									>
										<AccordionSummary
											expandIcon={
												<ExpandMoreIcon color="primary" />
											}
											aria-controls="title-and-subtitle-accordion-content"
											id="title-and-subtitle-accordion-header"
										>
											<Grid container spacing={2}>
												<Grid item>
													<TextFieldsIcon color="primary" />
												</Grid>

												<Grid item>
													<Typography
														variant="h3"
														color="primary"
														className={
															classes.titleAccordion
														}
													>
														Título e Subtítulo
													</Typography>
												</Grid>
											</Grid>
										</AccordionSummary>
										<AccordionDetails>
											<Grid container spacing={2}>
												<Grid item xs={12}>
													<TextField
														label="Título"
														type="text"
														value={
															editDataLanding?.titleLanding
														}
														onChange={({
															target
														}) =>
															handleChange(
																target.value,
																"titleLanding"
															)
														}
														name="titleLanding"
														variant="outlined"
														fullWidth
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	<Tooltip title="Alterar cor do título">
																		<IconButton
																			aria-label="Alterar cor do título"
																			edge="end"
																			name="color-picker-title"
																			onClick={
																				handlePopoverClick
																			}
																		>
																			<ColorIcon
																				className={
																					classes.titleColorPicker
																				}
																			/>
																		</IconButton>
																	</Tooltip>
																</InputAdornment>
															)
														}}
														helperText={
															validation.titleLanding
														}
														error={Boolean(
															validation.titleLanding
														)}
													/>
												</Grid>

												<Grid item xs={12}>
													<TextField
														label="Subtítulo"
														type="text"
														value={
															editDataLanding?.subtitleLanding
														}
														onChange={({
															target
														}) =>
															handleChange(
																target.value,
																"subtitleLanding"
															)
														}
														name="subtitleLanding"
														variant="outlined"
														fullWidth
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	<Tooltip title="Alterar cor do subtítulo">
																		<IconButton
																			aria-label="Alterar cor do subtítulo"
																			edge="end"
																			name="color-picker-subtitle"
																			onClick={
																				handlePopoverClick
																			}
																		>
																			<ColorIcon
																				className={
																					classes.subtitleColorPicker
																				}
																			/>
																		</IconButton>
																	</Tooltip>
																</InputAdornment>
															)
														}}
														helperText={
															validation.subtitleLanding
														}
														error={Boolean(
															validation.subtitleLanding
														)}
													/>
												</Grid>
											</Grid>
										</AccordionDetails>
									</Accordion>

									{userInfo.userType === "F" && (
										<Accordion
											square
											expanded={
												accordionOpen === "Botões"
											}
											onChange={() =>
												toggleAccordion("Botões")
											}
										>
											<AccordionSummary
												expandIcon={
													<ExpandMoreIcon color="primary" />
												}
												aria-controls="button-accordion-content"
												id="button-accordion-header"
											>
												<Grid container spacing={2}>
													<Grid item>
														<AddBoxIcon color="primary" />
													</Grid>

													<Grid item>
														<Typography
															variant="h3"
															color="primary"
															className={
																classes.titleAccordion
															}
														>
															Botões
														</Typography>
													</Grid>
												</Grid>
											</AccordionSummary>
											<AccordionDetails>
												<Grid item xs={12}>
													<TextField
														label="Botão"
														type="text"
														value={
															editDataLanding?.btnLanding ??
															"Entrar"
														}
														onChange={({
															target
														}) =>
															handleChange(
																target.value,
																"btnLanding"
															)
														}
														name="btnLanding"
														variant="outlined"
														fullWidth
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	<Tooltip title="Alterar cor do botão">
																		<IconButton
																			aria-label="Alterar cor do botão"
																			edge="end"
																			name="color-picker-button"
																			onClick={
																				handlePopoverClick
																			}
																		>
																			<ColorIcon
																				className={
																					classes.buttonColorPicker
																				}
																			/>
																		</IconButton>
																	</Tooltip>
																</InputAdornment>
															)
														}}
														helperText={
															validation.btnLanding
														}
														error={Boolean(
															validation.btnLanding
														)}
													/>
												</Grid>
											</AccordionDetails>
										</Accordion>
									)}

									<Accordion
										square
										expanded={accordionOpen === "Banner"}
										onChange={() =>
											toggleAccordion("Banner")
										}
									>
										<AccordionSummary
											expandIcon={
												<ExpandMoreIcon color="primary" />
											}
											aria-controls="banner-accordion-content"
											id="banner-accordion-header"
										>
											<Grid container spacing={2}>
												<Grid item>
													<WallpaperIcon color="primary" />
												</Grid>

												<Grid item>
													<Typography
														variant="h3"
														color="primary"
														className={
															classes.titleAccordion
														}
													>
														Banner
													</Typography>
												</Grid>
											</Grid>
										</AccordionSummary>
										<AccordionDetails>
											<Grid container justify="center">
												<Grid item>
													<DropzoneImage
														onDelete={
															handleDeleteImage
														}
														onChange={
															handleChangeImage
														}
														dataImg={
															editDataLanding?.bannerLanding ||
															null
														}
														typeImage="bannerLanding"
													/>
												</Grid>
											</Grid>
										</AccordionDetails>
									</Accordion>

									<Accordion
										square
										expanded={
											accordionOpen ===
											"Imagem de destaque"
										}
										onChange={() =>
											toggleAccordion(
												"Imagem de destaque"
											)
										}
									>
										<AccordionSummary
											expandIcon={
												<ExpandMoreIcon color="primary" />
											}
											aria-controls="image-hero-accordion-content"
											id="image-hero-accordion-header"
										>
											<Grid container spacing={2}>
												<Grid item>
													<PanoramaIcon color="primary" />
												</Grid>

												<Grid item>
													<Typography
														variant="h3"
														color="primary"
														className={
															classes.titleAccordion
														}
													>
														Imagem de destaque
													</Typography>
												</Grid>
											</Grid>
										</AccordionSummary>
										<AccordionDetails>
											<Grid container justify="center">
												<Grid item>
													<DropzoneImage
														onDelete={
															handleDeleteImage
														}
														onChange={
															handleChangeImage
														}
														dataImg={
															editDataLanding?.imageDemoLanding ||
															null
														}
														typeImage="imageDemoLanding"
													/>
												</Grid>
											</Grid>
										</AccordionDetails>
									</Accordion>

									<Accordion
										square
										expanded={
											accordionOpen === "Redes sociais"
										}
										onChange={() =>
											toggleAccordion("Redes sociais")
										}
									>
										<AccordionSummary
											expandIcon={
												<ExpandMoreIcon color="primary" />
											}
											aria-controls="social-media-accordion-content"
											id="social-media-accordion-header"
										>
											<Grid container spacing={2}>
												<Grid item>
													<ShareIcon color="primary" />
												</Grid>

												<Grid item>
													<Typography
														variant="h3"
														color="primary"
														className={
															classes.titleAccordion
														}
													>
														Redes sociais
													</Typography>
												</Grid>
											</Grid>
										</AccordionSummary>
										<AccordionDetails>
											<Grid container spacing={2}>
												<Grid item xs={12}>
													<TextField
														label="Facebook - Nome do perfil"
														type="text"
														value={
															editDataLanding?.linkFacebookLanding
														}
														onChange={({
															target
														}) =>
															handleChange(
																target.value,
																"linkFacebookLanding"
															)
														}
														name="linkFacebookLanding"
														variant="outlined"
														fullWidth
														InputProps={{
															startAdornment: (
																<Tooltip title="Insira apenas o nome do perfil">
																	<InputAdornment position="start">
																		facebook.com/
																	</InputAdornment>
																</Tooltip>
															)
														}}
														helperText={
															validation.linkFacebookLanding
														}
														error={Boolean(
															validation.linkFacebookLanding
														)}
													/>
												</Grid>

												<Grid item xs={12}>
													<TextField
														label="Instagram - Nome do perfil"
														type="text"
														value={
															editDataLanding?.linkInstagramLanding
														}
														onChange={({
															target
														}) =>
															handleChange(
																target.value,
																"linkInstagramLanding"
															)
														}
														name="linkInstagramLanding"
														variant="outlined"
														fullWidth
														InputProps={{
															startAdornment: (
																<Tooltip title="Insira apenas o nome do perfil">
																	<InputAdornment position="start">
																		instagram.com/
																	</InputAdornment>
																</Tooltip>
															)
														}}
														helperText={
															validation.linkInstagramLanding
														}
														error={Boolean(
															validation.linkInstagramLanding
														)}
													/>
												</Grid>

												<Grid item xs={12}>
													<TextField
														label="Website"
														type="text"
														value={
															editDataLanding?.linkSiteLanding
														}
														onChange={({
															target
														}) =>
															handleChange(
																target.value,
																"linkSiteLanding"
															)
														}
														name="linkSiteLanding"
														variant="outlined"
														fullWidth
														placeholder="https://beduka.com/"
														helperText={
															validation.linkSiteLanding
														}
														error={Boolean(
															validation.linkSiteLanding
														)}
													/>
												</Grid>
											</Grid>
										</AccordionDetails>
									</Accordion>

									<Accordion
										square
										expanded={accordionOpen === "Contato"}
										onChange={() =>
											toggleAccordion("Contato")
										}
									>
										<AccordionSummary
											expandIcon={
												<ExpandMoreIcon color="primary" />
											}
											aria-controls="contact-accordion-content"
											id="contact-accordion-header"
										>
											<Grid container spacing={2}>
												<Grid item>
													<ContactsIcon color="primary" />
												</Grid>

												<Grid item>
													<Typography
														variant="h3"
														color="primary"
														className={
															classes.titleAccordion
														}
													>
														Contato
													</Typography>
												</Grid>
											</Grid>
										</AccordionSummary>
										<AccordionDetails>
											<Grid container spacing={2}>
												<Grid item xs={12}>
													<TextField
														label="Email"
														type="text"
														value={
															editDataLanding?.emailLanding
														}
														onChange={({
															target
														}) =>
															handleChange(
																formatEmail(
																	target.value
																),
																"emailLanding"
															)
														}
														name="emailLanding"
														variant="outlined"
														fullWidth
														placeholder="Ex.: usuario@email.com"
														helperText={
															validation.emailLanding
														}
														error={Boolean(
															validation.emailLanding
														)}
													/>
												</Grid>

												<Grid item xs={12}>
													<TextField
														label="Telefone/Celular"
														type="text"
														value={
															editDataLanding?.telephoneLanding
														}
														onChange={({
															target
														}) =>
															handleChange(
																formatPhoneNumber(
																	target.value
																),
																"telephoneLanding"
															)
														}
														name="telephoneLanding"
														variant="outlined"
														fullWidth
														helperText={
															validation.telephoneLanding
														}
														error={Boolean(
															validation.telephoneLanding
														)}
													/>
												</Grid>
											</Grid>
										</AccordionDetails>
									</Accordion>

									<Accordion
										square
										expanded={accordionOpen === "Rodapé"}
										onChange={() =>
											toggleAccordion("Rodapé")
										}
									>
										<AccordionSummary
											expandIcon={
												<ExpandMoreIcon color="primary" />
											}
											aria-controls="footer-accordion-content"
											id="footer-accordion-header"
										>
											<Grid container spacing={2}>
												<Grid item>
													<FooterIcon color="primary" />
												</Grid>

												<Grid item>
													<Typography
														variant="h3"
														color="primary"
														className={
															classes.titleAccordion
														}
													>
														Rodapé
													</Typography>
												</Grid>
											</Grid>
										</AccordionSummary>
										<AccordionDetails>
											<Grid container justify="center">
												<Grid item>
													<ColorPicker
														selectedColor={
															editDataLanding?.footerLandingColor ??
															"#191919"
														}
														onChange={handleChangeColor(
															"footerLandingColor"
														)}
													/>
												</Grid>
											</Grid>
										</AccordionDetails>
									</Accordion>

									<Accordion
										square
										expanded={
											accordionOpen === "Site Próprio"
										}
										onChange={() =>
											toggleAccordion("Site Próprio")
										}
									>
										<AccordionSummary
											expandIcon={
												<ExpandMoreIcon color="primary" />
											}
											aria-controls="trademark-accordion-content"
											id="trademark-accordion-header"
										>
											<Grid container spacing={2}>
												<Grid item>
													<WebsiteIcon color="primary" />
												</Grid>

												<Grid item>
													<Typography
														variant="h3"
														color="primary"
														className={
															classes.titleAccordion
														}
													>
														Site Próprio
													</Typography>
												</Grid>
											</Grid>
										</AccordionSummary>
										<AccordionDetails>
											<Grid container spacing={2}>
												<Grid item xs={12}>
													<TextField
														label="Website principal"
														type="text"
														value={
															editDataLanding?.baseUrlLanding
														}
														onChange={({
															target
														}) =>
															handleChange(
																target.value,
																"baseUrlLanding"
															)
														}
														name="baseUrlLanding"
														variant="outlined"
														fullWidth
														placeholder="Ex.: https://beduka.com/"
														helperText={
															validation.baseUrlLanding
														}
														error={Boolean(
															validation.baseUrlLanding
														)}
													/>
												</Grid>

												<Grid item xs={12}>
													<Typography>
														Este campo permite
														alterar a landing page,
														para o seu próprio site.
													</Typography>
												</Grid>
											</Grid>
										</AccordionDetails>
									</Accordion>
								</Grid>
							)}

							<Grid
								item
								xs={12}
								sm
								className={clsx(
									classes.landingContainer,
									customClasses.scrollBar
								)}
							>
								<LandingPage
									dataLanding={{
										trademarkUrlLanding:
											editDataLanding?.trademarkUrlLanding,
										bannerLanding:
											editDataLanding?.bannerLanding,

										titleLanding:
											editDataLanding?.titleLanding,
										titleLandingColor:
											editDataLanding?.titleLandingColor,

										subtitleLanding:
											editDataLanding?.subtitleLanding,
										subtitleLandingColor:
											editDataLanding?.subtitleLandingColor,

										btnLanding: editDataLanding?.btnLanding,
										btnLandingColor:
											editDataLanding?.btnLandingColor,

										footerLandingColor:
											editDataLanding?.footerLandingColor,

										imageDemoLanding:
											editDataLanding?.imageDemoLanding,
										linkFacebookLanding:
											editDataLanding?.linkFacebookLanding,
										linkInstagramLanding:
											editDataLanding?.linkInstagramLanding,
										linkSiteLanding:
											editDataLanding?.linkSiteLanding,
										emailLanding:
											editDataLanding?.emailLanding,
										telephoneLanding:
											editDataLanding?.telephoneLanding
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Loading>

				<Popover
					id={anchorEl ? "color-picker-popover" : undefined}
					open={Boolean(anchorEl)}
					anchorEl={anchorEl}
					onClose={handlePopoverClose}
					anchorOrigin={{
						vertical: "center",
						horizontal: "right"
					}}
					transformOrigin={{
						vertical: "center",
						horizontal: "left"
					}}
				>
					{popoverInputType === "color-picker-title" && (
						<ColorPicker
							selectedColor={
								editDataLanding?.titleLandingColor ?? "#123456"
							}
							onChange={handleChangeColor("titleLandingColor")}
						/>
					)}

					{popoverInputType === "color-picker-subtitle" && (
						<ColorPicker
							selectedColor={
								editDataLanding?.subtitleLandingColor ??
								"#123456"
							}
							onChange={handleChangeColor("subtitleLandingColor")}
						/>
					)}

					{popoverInputType === "color-picker-button" && (
						<ColorPicker
							selectedColor={
								editDataLanding?.btnLandingColor ?? "#27C5DB"
							}
							onChange={handleChangeColor("btnLandingColor")}
						/>
					)}
				</Popover>
			</Grid>
		</Container>
	)
}

export default EditorLandingPage
