import React, { useState } from "react";
import {
	Grid,
	Button,
	IconButton,
	InputAdornment,
	TextField,
	Typography,
	Link as LinkMui,
	Tooltip,
	FormControl,
	FormControlLabel,
	Checkbox,
	FormHelperText,
	CircularProgress,
} from "@material-ui/core";
import {
	Visibility as ShowPassword,
	VisibilityOff as HiddenPassword,
	Help as HelpIcon,
} from "@material-ui/icons";

import { Link, useHistory } from "react-router-dom";

import { Divider, Loading, Notification } from "@/components";
import ApiService from "@/services/api";
import { pathName } from "@/services/history";

import { onlyNumberAndCommonLetter } from "@/utils/regex";
import { formatEmail } from "@/utils/sanitization";
import UserService from "@/services/UserService";
import useValidation from "@/hooks/useValidation";
import useDidMount from "@/hooks/useDidMount";

import CommonAuthContainer from "@/pages/Auth/components/Container";

import useStyles from "@/pages/Auth/styles";

type SignUpDataProps = {
	firstName: string;
	lastName: string;
	email: string;
	password: string;
	accessCode: string;
	termsOfUse: boolean;
};

const SignUpStudent = () => {
	const [signUpLoading, setSignUpLoading] = useState<boolean>(true);
	const [isEnableCode, setIsEnableCode] = useState<boolean>(false);

	const classes = useStyles();
	const history = useHistory();
	const { validation, triggerValidation, clearValidation } = useValidation();

	const pathUrl = pathName[1];

	const [accessCodeValid, setAccessCodeValid] = useState<boolean>(false);
	const [visiblePassword, setVisiblePassword] = useState<boolean>(false);
	const [signUpData, setSignUpData] = useState<SignUpDataProps>({
		firstName: "",
		lastName: "",
		email: "",
		password: "",
		accessCode: "",
		termsOfUse: false,
	} as SignUpDataProps);
	const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

	const getSponsorConfig = async () => {
		setSignUpLoading(true);
		try {
			const pathUrl = pathName[1];

			const { data } = await ApiService.get(
				`sponsor-config/landing-page/${pathUrl}`
			);

			setIsEnableCode(Boolean(data?.isEnableAccessCode));
		} catch (error) {
			// triggerUndeterminedValidation(error)
		}
		setSignUpLoading(false);
	};

	const submitSignUpData = async (
		event: React.FormEvent<HTMLFormElement>
	) => {
		event.preventDefault();

		setLoadingSubmit(true);
		try {
			await ApiService.post("auth/signup/student", {
				firstName: signUpData.firstName,
				lastName: signUpData.lastName,
				pathUrl,
				accessCode: signUpData.accessCode,
				email: signUpData.email,
				password: signUpData.password,
				confirmPassword: signUpData.password,
				termsOfUse: signUpData.termsOfUse,
			});

			UserService.logout();

			Notification.success({
				message: "Enviamos um e-mail para confirmação do seu cadastro!",
			});

			history.push("/signin");
		} catch (error) {
			triggerValidation(error);
		}

		setLoadingSubmit(false);
	};

	const handleSignUpDataChange = (
		type: keyof SignUpDataProps,
		value: string | boolean
	) => {
		clearValidation(type);

		setSignUpData((currentState) => ({
			...currentState,
			[type]: value,
		}));
	};

	const getValidateCode = async (value: string) => {
		if (value) {
			try {
				await ApiService.get("/access-code/validate", {
					params: {
						accessCode: value,
						pathUrl: pathUrl,
					},
				});

				setAccessCodeValid(true);

				Notification.success({
					message: "Código valido. Continue o cadastro!",
				});
			} catch (error) {
				triggerValidation(error);

				setAccessCodeValid(false);
			}
		}
	};

	const handleChangeCodeAccess = (value: string) => {
		let accessCode = value.toUpperCase();

		accessCode = accessCode.replace(onlyNumberAndCommonLetter, "");

		handleSignUpDataChange("accessCode", accessCode);
	};

	const handleClickShowPassword = () => {
		setVisiblePassword(!visiblePassword);
	};

	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault();
	};

	useDidMount(() => {
		getSponsorConfig();
	});

	return (
		<CommonAuthContainer containSocialMedia>
			<Loading loading={signUpLoading}>
				<>
					<Grid
						container
						spacing={1}
						justify="center"
						component="form"
						onSubmit={submitSignUpData}
					>
						{!isEnableCode && (
							<>
								<Grid item xs={10}>
									<TextField
										tabIndex={2}
										id="accessCode-signUp"
										name="accessCode"
										label="Código de Acesso"
										placeholder="Digite o código fornecido pela instituição"
										value={signUpData.accessCode}
										type="text"
										variant="outlined"
										fullWidth
										onChange={({ target }) =>
											handleChangeCodeAccess(target.value)
										}
										onBlur={({ target }) =>
											getValidateCode(target.value)
										}
										helperText={validation.accessCode}
										error={Boolean(validation.accessCode)}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<Tooltip title="Código informado pela instituição de ensino.">
														<HelpIcon />
													</Tooltip>
												</InputAdornment>
											),
										}}
									/>
								</Grid>
							</>
						)}

						<Grid item xs={10}>
							<Grid container spacing={1}>
								<Grid item xs={12} md={6}>
									<TextField
										id="firstName-signUp"
										tabIndex={3}
										label="Primeiro nome"
										type="text"
										name="firstName"
										placeholder="Ex.: Pedro"
										value={signUpData.firstName}
										onChange={({ target }) =>
											handleSignUpDataChange(
												"firstName",
												target.value
											)
										}
										variant="outlined"
										fullWidth
										helperText={validation.firstName}
										error={Boolean(validation.firstName)}
										disabled={
											!isEnableCode && !accessCodeValid
										}
									/>
								</Grid>

								<Grid item xs={12} md={6}>
									<TextField
										id="lastName-signUp"
										tabIndex={4}
										label="Último nome"
										type="text"
										name="lastName"
										placeholder="Ex.: Álvares Cabral"
										onChange={({ target }) =>
											handleSignUpDataChange(
												"lastName",
												target.value
											)
										}
										value={signUpData.lastName}
										helperText={validation.lastName}
										error={Boolean(validation.lastName)}
										disabled={
											!isEnableCode && !accessCodeValid
										}
										variant="outlined"
										fullWidth
									/>
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={10}>
							<TextField
								id="email-signUp"
								tabIndex={5}
								label="E-mail"
								name="email"
								placeholder="seunome@seuemail.com"
								variant="outlined"
								fullWidth
								onChange={({ target }) =>
									handleSignUpDataChange(
										"email",
										formatEmail(target.value)
									)
								}
								value={signUpData.email}
								helperText={validation.email}
								error={Boolean(validation.email)}
								disabled={!isEnableCode && !accessCodeValid}
							/>
						</Grid>

						<Grid item xs={10}>
							<TextField
								tabIndex={6}
								id="password-signUp"
								label="Senha"
								name="password"
								value={signUpData.password}
								onChange={({ target }) =>
									handleSignUpDataChange(
										"password",
										target.value
									)
								}
								type={visiblePassword ? "text" : "password"}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="Tornar senha visível"
												onClick={
													handleClickShowPassword
												}
												onMouseDown={
													handleMouseDownPassword
												}
												edge="end"
												disabled={
													!isEnableCode &&
													!accessCodeValid
												}
											>
												{visiblePassword ? (
													<ShowPassword />
												) : (
													<HiddenPassword />
												)}
											</IconButton>
										</InputAdornment>
									),
								}}
								fullWidth
								variant="outlined"
								helperText={validation.password}
								error={Boolean(validation.password)}
								disabled={!isEnableCode && !accessCodeValid}
							/>
						</Grid>

						<Grid item xs={10}>
							<FormControl
								required
								error={Boolean(validation.termsOfUse)}
								component="fieldset"
							>
								<FormControlLabel
									control={
										<Checkbox
											color="primary"
											checked={signUpData.termsOfUse}
											onChange={({ target }) =>
												handleSignUpDataChange(
													"termsOfUse",
													target.checked
												)
											}
											name="termsOfUse"
										/>
									}
									label={
										<Typography>
											Concordo com os{" "}
											<LinkMui
												component="a"
												href="https://beduka.com/termos-de-uso"
												color="inherit"
												target="_blank"
											>
												termos de uso
											</LinkMui>{" "}
											e com as{" "}
											<LinkMui
												component="a"
												href="https://beduka.com/politica-de-privacidade"
												color="inherit"
												target="_blank"
											>
												políticas de privacidade
											</LinkMui>
										</Typography>
									}
									className={classes.termsOfUseForm}
								/>

								{Boolean(validation.termsOfUse) && (
									<FormHelperText>
										{validation.termsOfUse}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>

						<Grid item xs={10}>
							<Button
								tabIndex={-1}
								color="primary"
								type="submit"
								variant="contained"
								endIcon={
									loadingSubmit && (
										<CircularProgress
											size={16}
											color="inherit"
										/>
									)
								}
								disabled={loadingSubmit}
								fullWidth
							>
								Criar conta gratuitamente
							</Button>
						</Grid>
					</Grid>

					<Divider size={3} orientation="horizontal" />

					<Typography align="center">
						Já possui uma conta?{" "}
						<LinkMui
							component={Link}
							to="/signin"
							color="inherit"
							underline="none"
						>
							Entre na sua conta
						</LinkMui>
					</Typography>
				</>
			</Loading>
		</CommonAuthContainer>
	);
};

export default SignUpStudent;
