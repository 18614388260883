import React, { useState } from "react"
import { Link } from "react-router-dom"

import {
	Button,
	Grid,
	IconButton,
	InputAdornment,
	Link as LinkMui,
	TextField,
	Typography
} from "@material-ui/core"
import {
	Visibility as ShowPassword,
	VisibilityOff as HiddenPassword
} from "@material-ui/icons"

import UserService from "@/services/UserService"

import Api from "@/services/api"
import { useCommonApi } from "@/services/api/commonApi"
import { pathName } from "@/services/history"

import useValidation from "@/hooks/useValidation"
import { Divider } from "@/components"

import { UserTypeEnum } from "@/protocols/UserProtocol"
import { formatEmail } from "@/utils/sanitization"

import CommonAuthStudent from "@/pages/Auth/components/Container"

type SignInDataProps = {
	email: string
	password: string
}

const AuthAdmin = () => {
	const commonApi = useCommonApi()
	const {
		validation,
		triggerValidation,
		clearValidation
	} = useValidation()

	const [visiblePassword, setVisiblePassword] = useState<boolean>(false)
	const [signInData, setSignInData] = useState<SignInDataProps>({
		email: "",
		password: ""
	})

	const handleClickShowPassword = () => {
		setVisiblePassword(!visiblePassword)
	}

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault()
	}

	const handleSignInDataChange = (type: keyof SignInDataProps, value: string) => {
		clearValidation(type)

		setSignInData((currentState) => ({
			...currentState,
			[type]: value
		}))
	}

	const submitSignInData = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		try {
			let userType = UserTypeEnum.SPONSOR

			if (pathName[1] === "enem-vip") {
				userType = UserTypeEnum.ADMIN
			}

			const { data } = await Api.post(`auth/signin/${userType}`, {
				email: signInData.email,
				password: signInData.password,
				pathUrl: pathName[1]
			})

			const { token } = data

			UserService.login(token)

			await commonApi.loadUserData()
		} catch (error) {
			triggerValidation(error)
		}
	}
	return (
		<CommonAuthStudent>

			<Grid
				spacing={1}
				container
				justify="center"
				component="form"
				onSubmit={submitSignInData}
			>
				<Grid item xs={10}>
					<TextField
						id="input-email"
						label="Email"
						tabIndex={1}
						placeholder="seunome@seuemail.com"
						name="email"
						type="email"
						value={signInData.email}
						onChange={({ target }) =>
							handleSignInDataChange(
								"email",
								formatEmail(target.value)
							)}
						variant="outlined"
						fullWidth
						helperText={validation.email}
						error={Boolean(validation.email)}
					/>
				</Grid>

				<Grid item xs={10}>
					<TextField
						id="input-password"
						label="Senha"
						tabIndex={2}
						name="password"
						value={signInData.password}
						type={visiblePassword ? "text" : "password"}
						onChange={
							({ target }) => handleSignInDataChange(
								"password",
								target.value
							)
						}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="Tornar senha visível"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{visiblePassword ? <ShowPassword /> : <HiddenPassword />}
									</IconButton>
								</InputAdornment>
							)
						}}
						fullWidth
						variant="outlined"
						helperText={validation.password}
						error={Boolean(validation.password)}
					/>

					<Divider size={1} orientation="horizontal" />

					<Typography>
									Esqueceu sua senha? {" "}
						<LinkMui
							component={Link}
							to={"/recuperar-senha"}
							underline="none"
						>
									Clique aqui
						</LinkMui>
					</Typography>
				</Grid>

				<Divider size={1} orientation="horizontal" />

				<Grid item xs={10}>
					<Button
						tabIndex={-1}
						color="primary"
						type="submit"
						variant="contained"
						fullWidth
					>
									Entrar
					</Button>
				</Grid>

			</Grid>

		</CommonAuthStudent>
	)
}

export default AuthAdmin
