import React from "react"
import { useSelector } from "react-redux"
import { PageTitle } from "@/layouts/layout-components"

import DashboardAdmin from "@/pages/Dashboard/UserAdmin"
import { RootStateProps } from "@/reducers"

export const Dashboard = () => {
	const { userInfo } = useSelector((state: RootStateProps) => state.UserConfig)

	return (
		<>
			<PageTitle icon="pe-7s-home" titleHeading="Dashboard" />

			{
				(
					userInfo.userType === "A" ||
					userInfo.userType === "F"
				) &&
				<DashboardAdmin />
			}
		</>
	)
}

export default Dashboard
