import React from "react";
import { connect, useSelector } from "react-redux";
import clsx from "clsx";

import { invertHex } from "@/utils/colors";
import * as S from "./styles";
import { setSidebarToggleMobile } from "@/reducers/ThemeOptions";
import {
	SidebarHeader,
	SidebarMenu,
	SidebarFooter,
} from "../../layout-components";

const Sidebar = (props) => {
	const toggleSidebarMobile = () => {
		const { sidebarToggleMobile, setSidebarToggleMobile } = props;
		setSidebarToggleMobile(!sidebarToggleMobile);
	};
	const { sidebarShadow, sidebarFooter, sidebarToggleMobile } = props;
	const { sponsorConfig } = useSelector((state) => state.UserConfig);
	const backgroundColor = sponsorConfig.backgroundColor || "#393737";

	return (
		<>
			<S.SidebarContainer
				backgroundColor={backgroundColor}
				color={invertHex({
					colorHex: backgroundColor,
					lightColor: "#f0f0f0",
					darkColor: "#191919",
				})}
				className={clsx("app-sidebar", {
					"app-sidebar--shadow": sidebarShadow,
				})}
			>
				<SidebarHeader />
				<div
					className="divider mt-4 opacity-4 rounded w-100"
					style={{
						backgroundColor: invertHex({
							colorHex: backgroundColor,
							lightColor: "#f1f1f1",
							darkColor: "#393737",
						}),
					}}
				/>
				<div className="app-sidebar--content">
					<SidebarMenu />
				</div>
				{sidebarFooter && <SidebarFooter />}
			</S.SidebarContainer>
			<div
				onClick={toggleSidebarMobile}
				className={clsx("app-sidebar-overlay", {
					"is-active": sidebarToggleMobile,
				})}
			/>
		</>
	);
};

const mapStateToProps = (state) => ({
	sidebarShadow: state.ThemeOptions.sidebarShadow,
	sidebarFooter: state.ThemeOptions.sidebarFooter,
	sidebarStyle: state.ThemeOptions.sidebarStyle,
	sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
});

const mapDispatchToProps = (dispatch) => ({
	setSidebarToggleMobile: (enable) =>
		dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
