import React, { useRef } from "react"

import PerfectScrollbar from "react-perfect-scrollbar"

import { useSelector } from "react-redux"
import RouterLink from "../ReactMetismenuRouterLink"

import MetisMenu from "react-metismenu"

import { SidebarUserbox } from "../../layout-components"

const sidebarMenuContent = {
	dashboard: {
		label: "Dashboard",
		icon: "pe-7s-home",
		description: "Acesse o dashboard",
		to: "/dashboard"
	},
	sponsor: {
		label: "Universidades / Faculdades",
		icon: "pe-7s-study",
		content: [
			{
				label: "Criar",
				description: "Crie uma faculdade / universidade",
				to: "/faculdade/criar"
			},
			{
				label: "Listar",
				description: "Liste todas faculdades / universidades",
				to: "/faculdade/listar"
			}
		]
	},
	student: {
		label: "Configurações",
		icon: "pe-7s-edit",
		description: "Configurações",
		to: "/editar-modulo"
	},
	editLandingPage: {
		label: "Landing Page",
		icon: "pe-7s-browser",
		description: "Landing page",
		to: "/editar-landing-page"
	},
	crm: {
		label: "Integração com CRM",
		icon: "pe-7s-portfolio",
		description: "Criar e editar CRM",
		to: "/crm"
	},
	accessCode: {
		label: "Códigos de acesso",
		icon: "pe-7s-key",
		content: [
			{
				label: "Criar",
				description: "Crie código de acesso",
				to: "/codigo-acesso/criar"
			},
			{
				label: "Listar",
				description: "Listar códigos de acesso",
				to: "/codigo-acesso/listar"
			}
		]
	},
	leads: {
		label: "Leads",
		icon: "pe-7s-users",
		description: "Listar clientes",
		to: "/leads"
	},
	default: {
		label: "Dashboard",
		icon: "pe-7s-home",
		description: "Acesse o dashboard",
		to: "/dashboard"
	},
	simulate: {
		label: "Novo Simulado",
		icon: "pe-7s-note",
		description: "Acesse o simulado",
		to: "/novo-simulado"
	},
	mySimulates: {
		label: "Meus Simulados",
		icon: "pe-7s-notebook",
		description: "Acesse o seus simulado",
		to: "/usuario-simulados"
	},
	writingCourseAdmin: {
		label: "Curso de redação",
		icon: "pe-7s-pen",
		description: "Crie, visualize e edite as aulas de redação",
		to: "/curso-redacao-admin"
	},
	writingCourse: {
		label: "Curso de redação",
		icon: "pe-7s-pen",
		description: "Visualize as aulas de redação",
		to: "/curso-redacao"
	},
	classificatory: {
		label: "Simulado Classificatório",
		icon: "pe-7s-note2",
		content: [
			{
				label: "Fazer simulado",
				to: "/simulado-classificatorio"
			},
			{
				label: "Ranqueamento",
				to: "/simulado-classificatorio/ranqueamento"
			}
		]
	},
	classificatoryRank: {
		label: "Ranqueamento",
		icon: "pe-7s-cup",
		to: "/simulado-classificatorio/ranqueamento"
	}
}

const SidebarMenu = () => {
	const menu = useRef()
	const {
		dashboard,
		sponsor,
		leads,
		student,
		simulate,
		editLandingPage,
		crm,
		accessCode,
		mySimulates,
		writingCourseAdmin,
		writingCourse,
		classificatory,
		classificatoryRank
	} = sidebarMenuContent

	const typesUser = {
		// ADMINISTRADOR
		A: [
			dashboard,
			sponsor,
			leads,
			student,
			editLandingPage,
			writingCourseAdmin,
			classificatoryRank
		],
		// FACULDADES
		F: [
			dashboard,
			student,
			editLandingPage,
			crm,
			accessCode,
			leads,
			classificatoryRank
		],
		// ESTUDANTES
		E: [simulate, mySimulates, classificatory],
		default: [dashboard]
	}

	const { sidebarUserbox } = useSelector((state) => state.ThemeOptions)
	const { userInfo, sponsorConfig } = useSelector(
		(state) => state.UserConfig
	)

	if (sponsorConfig.writingCourse) {
		typesUser.E.push(writingCourse)
		typesUser.F.push(writingCourse)
	}

	return (
		<>
			<PerfectScrollbar>
				{sidebarUserbox && <SidebarUserbox />}
				<div className="sidebar-navigation">
					<div className="sidebar-header"></div>
					<MetisMenu
						ref={menu}
						content={
							typesUser[userInfo.userType] || typesUser.default
						}
						LinkComponent={RouterLink}
						activeLinkFromLocation
						iconNamePrefix=""
						noBuiltInClassNames={true}
						classNameItemActive="active"
						classNameIcon="sidebar-icon"
						iconNameStateVisible="sidebar-icon-indicator"
						iconNameStateHidden="sidebar-icon-indicator"
						classNameItemHasVisibleChild="submenu-open"
						classNameItemHasActiveChild="active"
						classNameStateIcon="pe-7s-angle-down"
					/>
				</div>
			</PerfectScrollbar>
		</>
	)
}

export default SidebarMenu
