class StorageService {
	set (key: string, data: any): void {
		const stringData = JSON.stringify(data)

		const encodedData = btoa(stringData)

		localStorage.setItem(`@enemvip/${key}`, encodedData)
	}

	get<ExpectedData> (key: string): ExpectedData | null {
		const encodedData = localStorage.getItem(`@enemvip/${key}`)

		if (!encodedData) {
			return null
		}

		const stringData = atob(encodedData)

		const data: ExpectedData = JSON.parse(stringData)

		return data
	}

	delete (key: string): void {
		localStorage.removeItem(`@enemvip/${key}`)
	}
}

export default new StorageService()
