import {
	makeStyles,
	withStyles,
	Accordion as AccordionMui
} from "@material-ui/core"
import colors from "@/styles/colors"

type UseStylesProps = {
	titleColorPicker?: string
	subtitleColorPicker?: string
	buttonColorPicker?: string
	fullWidth?: boolean
	deviceIs?: "mobile" | "desktop"
}

export const Accordion = withStyles({
	root: {
		border: "1px solid rgba(0, 0, 0, .125)",
		borderRight: "0px",
		boxShadow: "none",
		"&:not(:last-child)": {
			borderBottom: 0
		},
		"&:before": {
			display: "none"
		},
		"&$expanded": {
			margin: "auto"
		}
	},
	expanded: {}
})(AccordionMui)

const useStyles = makeStyles((theme) => ({
	editorContainer: {
		width: "100%",
		maxWidth: "100vw",
		maxHeight: "100vh",
		overflow: "hidden"
	},
	headerContainer: {
		height: "60px",
		borderBottom: "2px solid #d1d1cf"
	},
	activeButtonHeader: {
		color: colors.palette.primary
	},
	titleAccordion: {
		fontSize: " 1.05rem",
		textTransform: "uppercase",
		fontWeight: 600
	},
	saveButtonContainer: {
		padding: 0,
		height: "100%",
		textAlign: "end"
	},
	saveButton: {
		width: "200px",
		maxWidth: "100%",
		height: "100%",
		borderRadius: "0px"
	},
	titleColorPicker: {
		"& path": {
			fill: (props: UseStylesProps) => `${props.titleColorPicker ?? "#123456"}`,
			fillOpacity: 1,
			stroke: "#e4e4e4"
		}
	},
	subtitleColorPicker: {
		"& path": {
			fill: (props: UseStylesProps) => `${props.subtitleColorPicker ?? "#123456"}`,
			fillOpacity: 1,
			stroke: "#e4e4e4"
		}
	},
	buttonColorPicker: {
		"& path": {
			fill: (props: UseStylesProps) => `${props.buttonColorPicker ?? "#27C5DB"}`,
			fillOpacity: 1,
			stroke: "#e4e4e4"
		}
	},
	consoleEditContainer: {
		transition: "0.3s all",
		display: (props: UseStylesProps) => `${props.fullWidth ? "block" : "none"}`,
		borderRight: "3px solid #d1d1cf",

		overflow: "auto",
		overflowY: "auto",
		maxHeight: "90vh",
		[theme.breakpoints.down("md")]: {
			maxHeight: "40vh"
		}
	},
	landingContainer: {
		height: "calc(100vh - 60px)",
		display: "flex",
		justifyContent: "center",
		overflowY: "auto",
		overflowX: "hidden",
		transition: (props: UseStylesProps) => `${!props.fullWidth ? "0.6s" : null}`,
		flex: (props: UseStylesProps) => `${!props.fullWidth ? "0 0 100%" : null} !important`,
		width: (props: UseStylesProps) => `100${!props.fullWidth ? "vw" : "%"}`,
		maxWidth: (props: UseStylesProps) => `${props.deviceIs === "mobile" ? "450px" : "auto"}`,
		margin: (props: UseStylesProps) => `${props.deviceIs === "mobile" ? "0 auto" : "0"}`
	}
}))

export default useStyles
