import React from "react"
import useCustomStyles from "@/styles/custom"
import { Typography } from "@material-ui/core"

import Chart from "react-apexcharts"
import { ApexOptions } from "apexcharts"
import { Divider } from "@/components"

type BasicPieChartProps = {
	categories: string[]
	data: number[]
}

const BasicPieChart: React.FC<BasicPieChartProps> = (props) => {
	const { categories, data } = props

	const customClasses = useCustomStyles()

	if (
		(categories && categories.length === 0) ||
		(data && data.length === 0)
	) {
		return (

			<>
				<Divider size={2} orientation="horizontal"/>

				<Typography
					align="center"
					variant="caption"
					className={customClasses.notFoundText}
				>
					Sem dados para processar...
				</Typography>

				<Divider size={2} orientation="horizontal"/>
			</>
		)
	}

	const options: ApexOptions = {
		chart: {
			width: 550,
			type: "pie"
		},
		labels: categories,
		responsive: [
			{
				breakpoint: 480,
				options: {
					chart: {
						width: 200
					},
					legend: {
						position: "bottom"
					}
				}
			}
		]
	}

	return <Chart options={options} series={data} type="pie" width={450} />
}

export default BasicPieChart
