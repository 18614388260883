import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid, makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
	dropzoneContainer: {
		borderRadius: theme.spacing(1),
		margin: theme.spacing(2, 0)
	}
}))

const DropzoneSkeleton = () => {
	const classes = useStyles()

	return (
		<Grid
			container
			spacing={2}
		>
			<Grid
				item
				xs={12}
			>
				<Skeleton
					variant="rect"
					width="100%"
					height={120}
					animation="wave"
					className={classes.dropzoneContainer}
				/>
			</Grid>
		</Grid>
	)
}

export default DropzoneSkeleton
