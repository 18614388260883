import React from "react"
import { CircularProgress, Grid, Fade } from "@material-ui/core"

import useStyles from "@/components/Loading/styles"

type LoadingProps = {
	customLoadingElement?: React.ReactNode
	loading: boolean
}

const Loading: React.FC<LoadingProps> = (props) => {
	const { children, loading, customLoadingElement } = props

	const classes = useStyles()

	let component

	if (loading) {
		if (customLoadingElement) {
			component = customLoadingElement
		} else {
			component = (
				<Grid
					container
					justify="center"
					alignItems="center"
					className={classes.container}
				>
					<CircularProgress color="primary" />
				</Grid>
			)
		}
	} else {
		component = (
			<Fade in={!loading}>
				{children as React.ReactElement}
			</Fade>
		)
	}

	return component as React.ReactElement
}

export default Loading
