import ReactDOM from "react-dom"

type VoidFunction = () => Promise<void> | void

export const renderComponent = (id: string, component: JSX.Element) => {
	let node = document.getElementById(id)

	if (node) {
		ReactDOM.unmountComponentAtNode(node)
	} else {
		node = document.createElement("div")
		node.setAttribute("id", id)
		document.body.appendChild(node)
	}

	const where = document.getElementById("root") || document.body

	const expectedRendering = ReactDOM.createPortal(component, where)

	ReactDOM.render(expectedRendering, node)
}

export const unmountComponent = (id: string) => {
	const node = document.getElementById(id)

	if (node) {
		ReactDOM.unmountComponentAtNode(node)
	}
}

/**
 * We use a workaround to run the selected function after
 * any react action (hooks, render, etc), since react make everything in a synchronous way
 * and the 'setTimeout' is asynchronous, so it runs after everything
 * that is synchronous.
 */
export const runAfterReactRender = (fn: VoidFunction) => {
	setTimeout(() => {
		fn()
	}, 0)
}

export const getPageFavicon = (): HTMLLinkElement | undefined => {
	const favicon = document.querySelector("link[rel~='icon']") as HTMLLinkElement

	return favicon
}

export const changePageFavicon = (src: string) => {
	let favicon = getPageFavicon()

	if (!favicon) {
		favicon = document.createElement("link")
		favicon.rel = "icon"

		document.head.appendChild(favicon)
	}

	favicon.href = src
}
