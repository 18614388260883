import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid } from "@material-ui/core"

const AdditionalInfoSkeleton = () => {
	return (
		<Grid
			container
			spacing={2}
		>

			<Grid item xs={12}>
				<Skeleton
					variant="rect"
					width="100%"
					height={40}
					animation="wave"
				/>
			</Grid>

			<Grid item xs={12}>
				<Skeleton
					variant="rect"
					width="100%"
					height={40}
					animation="wave"
				/>
			</Grid>

			<Grid item xs={12}>
				<Skeleton
					variant="rect"
					width="100%"
					height={40}
					animation="wave"
				/>
			</Grid>

			<Grid item xs={12}>
				<Skeleton
					variant="rect"
					width="100%"
					height={40}
					animation="wave"
				/>
			</Grid>

			<Grid item xs={12}>
				<Skeleton
					variant="rect"
					width="100%"
					height={40}
					animation="wave"
				/>
			</Grid>

			<Grid item xs={12}>
				<Skeleton
					variant="rect"
					width="100%"
					height={40}
					animation="wave"
				/>
			</Grid>

			<Grid item xs={12}>
				<Grid container justify="center">
					<Skeleton
						variant="rect"
						width="40%"
						height={30}
						animation="wave"
					/>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default AdditionalInfoSkeleton
