import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	logo: {
		maxHeight: "72px",

		display: "flex",
		margin: "0 auto"
	},
	container: {
		height: "100%",
		display: "flex",
		alignContent: "center"
	},
	wrapperAuth: {
		maxHeight: "-webkit-fill-available"
	},
	termsOfUseForm: {
		marginBottom: 0
	}
})

export default useStyles
