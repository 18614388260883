import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	card: {
		flex: 1,
		position: "relative",
		padding: theme.spacing(2),
		background: theme.palette.background.paper,
		borderRadius: theme.spacing(1),
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	cardLoadingContainer: {
		position: "absolute",
		zIndex: 9,
		width: "100%",
		height: "100%",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "rgba(255, 255, 255, 0.7)"
	}
}))

export default useStyles
