import CodeMessages from "@/utils/responseMessages/codeMessages"

const Messages = (code_message: string): string => {
	const messageResponse = (CodeMessages as { [key: string]: string })[code_message] ||
		"Algo deu errado e não conseguimos identificar. Entre em contato."

	return messageResponse
}

export default Messages
