import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	docContainer: {
		zIndex: 5,
		width: "100%",
		padding: theme.spacing(3, 2),
		cursor: "pointer",
		transition: "all .2s ease-in-out",

		border: `1px dashed ${colors.palette.primary}`,
		borderRadius: theme.spacing(1),
		backgroundColor: "#3b469933",
		margin: theme.spacing(2, 0),

		textAlign: "center",

		color: colors.grayScale[1],

		"&:hover": {
			opacity: 0.75
		}
	},
	textDoc: {
		fontSize: "0.95rem"
	},
	iconDoc: {
		fontSize: "4.5rem"
	}
}))

export default useStyles
