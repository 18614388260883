import React, { Fragment, Component } from "react";

import clsx from "clsx";

import { connect } from "react-redux";
import { setSidebarToggleMobile } from "@/reducers/ThemeOptions";

import { NavLink as RouterLink } from "react-router-dom";

class SidebarCollapsed extends Component {
	toggleSidebarMobile = () => {
		let { sidebarToggleMobile, setSidebarToggleMobile } = this.props;
		setSidebarToggleMobile(!sidebarToggleMobile);
	};

	render() {
		let { sidebarShadow, sidebarStyle, sidebarToggleMobile } = this.props;

		return (
			<Fragment>
				<div
					className={clsx(
						"app-sidebar app-sidebar--collapsed app-sidebar--mini",
						sidebarStyle,
						{ "app-sidebar--shadow": sidebarShadow }
					)}
				>
					<div className="app-sidebar--header">
						<div className="nav-logo">
							<RouterLink
								to="/dashboard"
								activeClassName="active"
								title="EnemVip"
							>
								<i>EnemVip</i>
								<span>EnemVip</span>
							</RouterLink>
						</div>
					</div>
				</div>
				<div
					onClick={this.toggleSidebarMobile}
					className={clsx("app-sidebar-overlay", {
						"is-active": sidebarToggleMobile,
					})}
				/>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	sidebarShadow: state.ThemeOptions.sidebarShadow,
	sidebarStyle: state.ThemeOptions.sidebarStyle,
	sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
});

const mapDispatchToProps = (dispatch) => ({
	setSidebarToggleMobile: (enable) =>
		dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarCollapsed);
