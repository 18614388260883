import { invertHex } from "@/utils/colors"
import styled from "styled-components"

export const NavLogo = styled.div`
    width: 100%;
    height: 100%;

    img {
        width: 100%;
        height: 100%;
    }
`

export const SidebarHeader = styled.section`
	.hamburger {
		.hamburger-inner {
			&,
			&::before,
			&::after {
				background-color: ${({ backgroundColor }) =>
		invertHex({
			colorHex: backgroundColor,
			lightColor: "#ffffff",
			darkColor: "#303030"
		})};
			}
		}

		&.is-active {
			.hamburger-inner,
			.hamburger-inner::before,
			.hamburger-inner::after {
				background-color: ${({ backgroundColor }) =>
		invertHex({
			colorHex: backgroundColor,
			lightColor: "#ffffff",
			darkColor: "#303030"
		})};
			}
		}
	}
`
