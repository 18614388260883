type InvertHexProps = {
	colorHex?: string
	lightColor: string
	darkColor: string
}

export const invertHex = (data: InvertHexProps): string => {
	const {
		colorHex,
		darkColor,
		lightColor
	} = data

	let formattedColor = colorHex || "#ffffff"

	formattedColor = formattedColor.charAt(0) === "#"
		? formattedColor.substring(1, 7)
		: formattedColor

	// Ex.: FFF => FFFFFF
	if (formattedColor.length === 3) {
		formattedColor = formattedColor.repeat(2)
	}

	const r = parseInt(formattedColor.substring(0, 2), 16) // hexToR
	const g = parseInt(formattedColor.substring(2, 4), 16) // hexToG
	const b = parseInt(formattedColor.substring(4, 6), 16) // hexToB

	return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor
}
