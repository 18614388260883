import styled from "styled-components"
import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"
import { invertHex } from "@/utils/colors"

const landingPageColors = {
	black: "#212121",
	grey: "#9C9C9C",
	greyLight: "#c7c7c7",
	textWhite: "rgba(255, 255, 255, 0.5)"
}

type LandingPageContainerProps = {
	isIndexPage?: boolean;

	btnColor?: string;
};

export const LandingPageContainer = styled.div<LandingPageContainerProps>`
	height: ${({ isIndexPage }) => `100${isIndexPage ? "vh" : "%"}`};
	width: ${({ isIndexPage }) => `100${isIndexPage ? "vw" : "%"}`};

	font-size: calc(1em + 1vw);

	h1 {
		font-weight: 700;
		font-size: 2.5em;
	}
	h2 {
		font-weight: 500;
		font-size: 2em;
	}
	h3 {
		font-weight: 400;
		font-size: 1.5em;
	}
	p {
		font-weight: 400;
		font-size: 1em;
	}

	@media only screen and (max-width: 768px) {
		h1 {
			font-weight: 700;
			font-size: 3em;
		}
	}

	.section-container {
		width: 100%;
	}
	.section-content {
		margin: 0 auto;
		max-width: 1280px;
		padding: 16px 64px 15px;
	}

	.btnAccessPlatform {
		color: ${({ btnColor }) =>
		invertHex({
			colorHex: btnColor,
			lightColor: "#ffffff",
			darkColor: "#303030"
		})};
		background-color: ${({ btnColor }) =>
		btnColor ? `${btnColor}` : "#11c5db"};
		border-color: ${({ btnColor }) =>
		btnColor ? `${btnColor}` : "#11c5db"};
		box-shadow: 0 0.25rem 0.55rem rgb(33 34 35 / 35%);
	}

	@media only screen and (max-width: 768px) {
		overflow-x: hidden;
		.section-content {
			max-width: 650px;
			padding: 24px 22px 22px;
		}
	}
`

type HeroContainerProps = {
	bannerTop?: string;
};

export const HeroContainer = styled.div<HeroContainerProps>`
	background-image: ${({ bannerTop }) => `url(${bannerTop})`};
	background-attachment: scroll;
	background-position: bottom;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
	max-height: 400px;
`

export const HeaderContainer = styled.header`
	width: 100%;

	.header-content {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-content: center;
		align-items: center;
	}

	.logo-header {
		width: 100%;
		max-width: 110px;
	}
`

type PlacardContainerProps = {
	titleColor?: string;
	subTitleColor?: string;
};

export const PlacardContainer = styled.section<PlacardContainerProps>`
	height: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	text-align: center;

	.placardText-content {
		align-self: flex-start;

		.mainTitle-plancard {
			text-transform: uppercase;
			color: ${({ titleColor }) =>
		titleColor
			? `${titleColor} !important`
			: `${landingPageColors.black}`};
		}
		.subtitle-plancard {
			color: ${({ subTitleColor }) =>
		subTitleColor
			? `${subTitleColor} !important`
			: `${landingPageColors.black}`};
		}
	}

	@media only screen and (max-width: 768px) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 60%;
	}
`

export const DemoPlatformContainer = styled.section`
	.demoPlatform-content {
		height: 100%;
		max-height: 90px;
		text-align: center;
		position: relative;
	}
	.imageExample-wrapper-demoPlatform {
		top: -120px;
		position: relative;
		width: 100%;
		height: 100%;
	}
	.imageExample-item-demoPlatform {
		width: auto;
		max-width: 500px;
		max-height: 275px;

		box-shadow: 0px 2px 6px 3px rgba(57, 55, 55, 0.5);

		-webkit-transform: translatey(0);
		transform: translatey(0);
		-webkit-animation: float 5s ease-in-out infinite;
		animation: float 5s ease-in-out infinite;
	}

	@media only screen and (max-width: 768px) {
		display: none;
	}

	@-webkit-keyframes float {
		0% {
			-webkit-transform: translatey(0);
			transform: translatey(0);
		}
		50% {
			-webkit-transform: translatey(-12px);
			transform: translatey(-12px);
		}
		100% {
			-webkit-transform: translatey(0);
			transform: translatey(0);
		}
	}
	@keyframes float {
		0% {
			-webkit-transform: translatey(0);
			transform: translatey(0);
		}
		50% {
			-webkit-transform: translatey(-12px);
			transform: translatey(-12px);
		}
		100% {
			-webkit-transform: translatey(0);
			transform: translatey(0);
		}
	}

	@media only screen and (max-width: 768px) {
		@-webkit-keyframes float {
			0% {
				-webkit-transform: translatey(0);
				transform: translatey(0);
			}
			50% {
				-webkit-transform: translatey(-8px);
				transform: translatey(-8px);
			}
			100% {
				-webkit-transform: translatey(0);
				transform: translatey(0);
			}
		}
		@keyframes float {
			0% {
				-webkit-transform: translatey(0);
				transform: translatey(0);
			}
			50% {
				-webkit-transform: translatey(-8px);
				transform: translatey(-8px);
			}
			100% {
				-webkit-transform: translatey(0);
				transform: translatey(0);
			}
		}
	}
`

type FooterContainerProps = {
	backgroundColor?: string;
};

export const FooterContainer = styled.section<FooterContainerProps>`
	background-color: ${({ backgroundColor }) =>
		backgroundColor ? `${backgroundColor} !important` : "#191919"};
	color: ${({ backgroundColor }) =>
		invertHex({
			colorHex: backgroundColor || "#191919",
			lightColor: "#ffffff",
			darkColor: "#191919"
		})} !important;
	text-align: center;

	.infos-content-footerContainer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		align-content: center;
	}

	.logo-footer {
		width: 100%;
		max-width: 110px;
	}

	.icon-socialMedia-footer,
	.border-divider-footer {
		color: ${({ backgroundColor }) =>
		invertHex({
			colorHex: backgroundColor,
			lightColor: "#ffffff",
			darkColor: "#191919"
		})} !important;
	}

	.contact-wrapper-footer {
		ul {
			padding: 0;
			list-style-type: none;
			li {
				font-size: 0.7em;
				display: inline;
				font-size: 16px;
			}
			li,
			a {
				color: ${({ backgroundColor }) =>
		invertHex({
			colorHex: backgroundColor,
			lightColor: "#ffffff",
			darkColor: "#191919"
		})};
			}

			li:first-child:after {
				content: "❘";
				padding: 0 10px;
			}
			span {
				margin-right: 6px;
			}
		}
	}

	.copyright-footer {
		font-size: 12px;

		.text-copyright {
			color: ${({ backgroundColor }) =>
		invertHex({
			colorHex: backgroundColor || "#191919",
			lightColor: "#ffffff",
			darkColor: "#191919"
		})} !important;
		}
	}
`

type UseStylesProps = {
	btnLandingColor?: string;
	footerLandingColor?: string;
};

const useStyles = makeStyles((theme) => ({
	titleIntro: {
		fontSize: "1.9em !important",
		fontWeight: 600,
		color: "#313131",
		lineHeight: "64px"
	},
	subtitleIntro: {
		color: colors.grayScale[5],
		fontSize: "1.2rem !important"
	},
	buttonHeader: {
		padding: theme.spacing(1, 3),
		textTransform: "uppercase",
		color: (props: UseStylesProps) =>
			invertHex({
				colorHex: props?.btnLandingColor,
				lightColor: "#ffffff",
				darkColor: "#303030"
			}),
		backgroundColor: (props: UseStylesProps) =>
			props?.btnLandingColor ? `${props?.btnLandingColor}` : "#11c5db",
		borderColor: (props: UseStylesProps) =>
			props?.btnLandingColor ? `${props?.btnLandingColor}` : "#11c5db",
		fontSize: "16px",
		"&:hover": {
			opacity: 0.8,
			color: (props: UseStylesProps) =>
				invertHex({
					colorHex: props?.btnLandingColor,
					lightColor: "#ffffff",
					darkColor: "#303030"
				}),
			backgroundColor: (props: UseStylesProps) =>
				props?.btnLandingColor
					? `${props?.btnLandingColor}`
					: "#11c5db",
			borderColor: (props: UseStylesProps) =>
				props?.btnLandingColor
					? `${props?.btnLandingColor}`
					: "#11c5db"
		}
	},

	boxIconIntro: {
		width: "80px",
		height: "80px",
		margin: "0 auto",

		display: "flex",
		justifyContent: "center",
		alignContent: "center",

		backgroundColor: colors.palette.primary,

		boxShadow: "0px 2px 6px 1px rgba(57,55,55,0.27)"
	},
	titleItemIntro: {},
	descriptionItemIntro: {
		fontSize: "1.05rem !important",
		color: colors.grayScale[5],
		width: "80%",
		margin: "0 auto"
	},
	iconItemIntro: {
		fontSize: theme.spacing(6),
		height: "100%",
		color: colors.grayScale[10]
	},

	socialMediaFooter: {
		color: (props: UseStylesProps) =>
			`${invertHex({
				colorHex: props.footerLandingColor,
				lightColor: "#ffffff",
				darkColor: "#191919"
			})} !important`
	}
}))

export default useStyles
