import React, { useState } from "react"
import { useDropzone } from "react-dropzone"
import { Grid, Typography } from "@material-ui/core"
import {
	Description as FileIcon
} from "@material-ui/icons"

import Loading from "@/components/Loading"

import useStyles from "@/components/DropzoneDocs/styles"
import Divider from "@/components/Divider"
import DropzoneSkeleton from "@/skeletons/DropzoneSkeleton"

type DropzoneDocsProps = {
	docFile: string | null
	onChange: (file: File) => void
}

const DropzoneDocs: React.FC<DropzoneDocsProps> = (props) => {
	const { onChange, docFile } = props

	const [loading, setLoading] = useState<boolean>(false)
	const [doc, setDoc] = useState<File>()

	const classes = useStyles()

	const onDrop = ([newFile]: File[]) => {
		setLoading(true)

		setDoc(newFile)

		onChange(newFile)

		setLoading(false)
	}

	const { getRootProps, getInputProps } = useDropzone({ onDrop })

	return (
		<Grid container justify='center' spacing={3}>
			<Grid item xs={12} {...getRootProps()}>
				<Loading loading={loading} customLoadingElement={<DropzoneSkeleton />}>
					<>
						<input {...getInputProps()} accept=".pdf" type="file" hidden />
						<Grid container justify='center' spacing={2} className={classes.docContainer}>
							{docFile && !doc ? (
								<Grid item xs={12}>
									<FileIcon className={classes.iconDoc} />

									<Divider size={3} orientation="horizontal" />

									<Typography color="inherit" variant="caption" className={classes.textDoc}>
										<b>
											{docFile.replace(
												"https://beta-enemvip-web-assets.s3.amazonaws.com/lessonPDF/",
												""
											)}
										</b>
									</Typography>

									<Divider size={2} orientation="horizontal" />

									<Typography color="inherit" variant="caption" className={classes.textDoc}>
										Arraste o arquivo ou clique aqui para alterar
									</Typography>
								</Grid>
							) : !doc ? (
								<Grid item xs={12}>
									<Typography color="inherit" variant="caption" className={classes.textDoc}>
										Arraste o arquivo ou clique aqui
									</Typography>
								</Grid>
							) : (
								<Grid item xs={12}>
									<FileIcon className={classes.iconDoc} />

									<Divider size={3} orientation="horizontal" />

									<Typography color="inherit" variant="caption" className={classes.textDoc}>
										<b>
											{doc?.name}
										</b>
									</Typography>

									<Divider size={2} orientation="horizontal" />

									<Typography color="inherit" variant="caption" className={classes.textDoc}>
										Arraste o arquivo ou clique aqui para alterar
									</Typography>
								</Grid>
							)}
						</Grid>
					</>
				</Loading>

			</Grid>
		</Grid>
	)
}

export default DropzoneDocs
