import React from "react"
import { Route } from "react-router-dom"

import {
	LandingPage,
	AuthAdmin,
	NotFound
} from "@/pages"

import SignInStudent from "@/pages/Auth/Student/SignIn"
import SignUpStudent from "@/pages/Auth/Student/SignUp"
import RequestRecovery from "@/pages/Auth/Recovery/RequestRecovery"
import ResetPassword from "@/pages/Auth/Recovery/ResetPassword"

import { onLoadLanding } from "@/routes"

const PublicRoutes = [
	<Route key="notFound" exact path="/404" component={NotFound} />,
	<Route
		key="landingPage"
		exact
		path="/"
		component={(props: any) => {
			return <LandingPage {...props} onLoad={onLoadLanding} />
		}}
	/>,
	<Route
		key="admin"
		exact
		path="/admin"
		component={AuthAdmin} />,
	<Route
		key="auth"
		path="/signin"
		component={SignInStudent}
	/>,
	<Route
		key="auth"
		path="/signup"
		component={SignUpStudent}
	/>,
	<Route
		key="requestRecovery"
		exact
		path="/recuperar-senha"
		component={RequestRecovery}
	/>,
	<Route
		key="resetPassword"
		exact
		path="/recuperar-senha/reset/:token"
		component={ResetPassword}
	/>
]

export default PublicRoutes
