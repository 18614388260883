import React from "react"

import { Col } from "reactstrap"

import svgImage3 from "@/assets/images/illustrations/404.svg"

const LivePreviewExample = () => {
	return (
		<>
			<div className="app-wrapper bg-white">
				<div className="app-main">
					<div className="app-content p-0">
						<div className="app-inner-content-layout--main">
							<div className="flex-grow-1 w-100 d-flex align-items-center">
								<div className="bg-composed-wrapper--content">
									<div className="hero-wrapper bg-composed-wrapper min-vh-100">
										<div className="flex-grow-1 w-100 d-flex align-items-center">
											<Col
												lg="6"
												md="9"
												className="px-4 px-lg-0 mx-auto text-center text-black"
											>
												<img
													src={svgImage3}
													className="w-50 mx-auto d-block my-5 img-fluid"
													alt="..."
												/>

												<h3 className="font-size-xxl line-height-sm font-weight-light d-block px-3 mb-3 text-black-50">
                                                    Desculpe! Não encontramos a página procurada
												</h3>
												<p>
                                                    Você pode ter digitado incorretamente o endereço
                                                    ou a página pode ter se movido.
												</p>
											</Col>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default LivePreviewExample
