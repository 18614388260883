import React from "react"
import { useSelector, connect } from "react-redux"
import { Tooltip } from "@material-ui/core"

import clsx from "clsx"
import { Link } from "react-router-dom"
import {
	Adjust as AdjustIcon,
	ArrowForwardIos as OpenIcon
} from "@material-ui/icons"

import {
	setSidebarToggleMobile,
	setSidebarToggle
} from "@/reducers/ThemeOptions"

import * as S from "./styles"
import bedukaLogoImg from "@/assets/images/logo/enemvip-logo.svg"
import { invertHex } from "@/utils/colors"

const SidebarHeader = (props) => {
	const { sidebarToggleMobile, sidebarToggle } = useSelector(
		(state) => state.ThemeOptions
	)
	const { sponsorConfig } = useSelector((state) => state.UserConfig)

	const backgroundColor = sponsorConfig?.backgroundColor || "#393737"

	const toggleSidebarMobile = () => {
		const { setSidebarToggleMobile } = props
		setSidebarToggleMobile(!sidebarToggleMobile)
	}

	const toggleSidebar = () => {
		const { setSidebarToggle } = props
		setSidebarToggle(!sidebarToggle)
	}

	return (
		<S.SidebarHeader
			backgroundColor={backgroundColor}
			className="app-sidebar--header"
		>
			<S.NavLogo>
				<Link to="/dashboard">
					<img
						style={{ maxWidth: "200px", objectFit: "contain" }}
						src={sponsorConfig?.trademarkUrl || bedukaLogoImg}
						alt="Foto do usuário"
						className="mt-2"
					/>
				</Link>
			</S.NavLogo>

			<Tooltip title="Fechar Menu">
				<button
					onClick={toggleSidebar}
					className="btn btn-sm collapse-sidebar-btn"
					id="CollapseSidebarTooltip"
				>
					<AdjustIcon
						style={{
							color: invertHex({
								colorHex: backgroundColor,
								lightColor: "#f1f1f1",
								darkColor: "#393737"
							})
						}}
					/>
				</button>
			</Tooltip>

			<button
				className={clsx(
					"navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn",
					{ "is-active": sidebarToggleMobile }
				)}
				onClick={toggleSidebarMobile}
				style={{
					color: invertHex({
						colorHex: backgroundColor,
						lightColor: "#f1f1f1",
						darkColor: "#393737"
					})
				}}
			>
				<span className="hamburger-box">
					<span className="hamburger-inner" />
				</span>
			</button>

			<Tooltip title="Abrir Menu">
				<button
					onClick={toggleSidebar}
					className="expand-sidebar-btn btn btn-sm"
					id="ExpandSidebarTooltip"
				>
					<OpenIcon
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							color: invertHex({
								colorHex: backgroundColor,
								lightColor: "#f1f1f1",
								darkColor: "#393737"
							})
						}}
					/>
				</button>
			</Tooltip>
		</S.SidebarHeader>
	)
}
const mapDispatchToProps = (dispatch) => ({
	setSidebarToggleMobile: (enable) =>
		dispatch(setSidebarToggleMobile(enable)),
	setSidebarToggle: (enable) => dispatch(setSidebarToggle(enable))
})

export default connect("", mapDispatchToProps)(SidebarHeader)
