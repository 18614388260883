import React from "react"

import { ClimbingBoxLoader } from "react-spinners"

const SuspenseLoading = () => {
	return (
		<>
			<div className="d-flex align-items-center flex-column vh-100 vw-100 justify-content-center text-center py-3">
				<div className="d-flex align-items-center flex-column px-4">
					<ClimbingBoxLoader color={"#3c44b1"} loading={true} />
				</div>
				<div className="text-muted font-size-xl text-center pt-3">Carregando...</div>
			</div>
		</>
	)
}

export default SuspenseLoading
