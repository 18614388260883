import { makeStyles } from "@material-ui/core"

type UseStylesProps = {
	isLoading: boolean
}

const useStyles = makeStyles({
	imageContainer: {
		position: "relative",
		width: "100%",
		maxHeight: "inherit",
		boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)"
	},
	imageContent: {
		position: "absolute",
		top: 0,

		height: "100%",
		width: "100%",

		opacity: 0,
		transition: "0.5s ease",

		"&:hover": {
			opacity: "1",
			backgroundColor: "rgba(0, 0, 0, 0.25)"
		}
	},
	boxUploadImage: {
		height: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	},
	image: {
		display: "block",
		width: "100%",
		height: "auto",
		maxHeight: "inherit",

		objectFit: "contain",
		background: "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAIAAABLbSncAAADvHpUWHRSYXcgcHJvZmlsZSB0eXBlIGV4aWYAAHjarZZtluQqCIb/s4pZQkARXY6J8ZzZwV3+vBonVWV9T9/YFQxFEHjQatr/+13pFy5OYSGvFkMKYcHlk0+SMYnLcR2SF9/v17r2fKOn8wuBykG64zHswz5Dr5cXzA/9eqsn24afOBzx6bhfrq3c5sMuDkdODj2PZ0rjveyvQh8f2Ybb4Xx+9oZiFIU/JyS7Y7fgHtsqDhG45DJk7HeRplHM2Rnu3unj2tE5nYp3zqbaLXno3W0paAnDIEw1GnrWSe/OZeQmIr6sfPNFknOJu9rVWmKt+5Fd9gGVCjSS+ptKn8FwRSldfy1gGD6KufWRMCJS3ECsgOaKsREnFlS7sufCmSvvXW68IUQvuxikyCau66IzSbJ1KL4NrmLAUwh0xG2g5qCWMxbu66a+3sYRKxeGpTCcMd64G/RI+S/jdFRra13mJZ61QlzSehphNHLtDisA4Tpqqr2+fdBV3yxXYB0Iai9zRIJ5WQ8Xq/Klt1zn7GCni6fl2BpsZThAibC2Ihh2ILAENDYHXkzEmFHHCD4ZkYvzsoIAq0phqmDjXACcKG1tvGPcbUXlUONoaVvDBWyS2DYQYHmv6B/zET2U1aknVQ1qGjVpDi74oCEEC+2MyubMm1ows2jJcnTRR40hWowxxZwkORxhmkIySjGllDMWzXCd8XaGRc6rrG71q65htTWuac0b2mfzm25hsy1uactFiivY/iUUoxJLKnnnHa20+133sNse97Tnil6rrvqqNVSrsaaaT2qD6i01nsi9psaDWiPmu51dqEFt9tcFt+NEGzMQE88gbo0AGloasyWy99LINWbY3dgUKqDG2uAUbsRA0O8sWvlkdyH3khup/4qbPCNHDd3/QY4aukHuntsDaiX3XxTXAbVd2Gq6uIqDrdqeJeIP5/FnkrvI7TfskOh8Uvbafp0O6cJk8k7iEDyc00+iuA6CfhLF9WL0MorV32au8tSCJtNnMsTSp3D2WNLdF2VaVNI7iy7pRWbOf1E96pMwxc0+T6bhTWYLTQqeqL6XAzpdNYb6H3Qn9cZ4232z9DORPKe2pC8z07FR6SdRXAdB/xLFI0mvDGTuirBPUeoZJX229+/b+10fLXf7dJ2JPm4Kel7c/FWpqDXevB/WOaw7i3tJt4rt6+O7jLXorEV5ZvJZhqhRsHdEZjmHLXs5TsjnJu+kO6tHn574c9hsYcb/bWacyrdb5Ju+oA8ze9sXtLyj/mFf0IvMDP97JPoDzg953w8pPNUAAAAJcEhZcwAALiMAAC4jAXilP3YAAAAHdElNRQfjCAISETJC0UVIAAAARElEQVQI13WNMQ7AMAwCAWXz/z/KA0yHpG0W3wQCBLsbAIAktj8rvCTBhTCwkuxuEpLSqS7bW5GsKpIn+N+kezF+jMED1UQd4NDc6rsAAAAASUVORK5CYII=')"
	},
	imageWrapper: {
		textAlign: "center",
		cursor: (props: UseStylesProps) => (!props.isLoading ? "pointer" : "wait")
	},
	uploadTrademarkContainer: {
		textAlign: "center",
		cursor: (props: UseStylesProps) => (!props.isLoading ? "pointer" : "wait")
	},
	iconUploadTrademark: {
		fontSize: "42px"
	},
	textUploadTrademark: {
		fontSize: "16px"
	}
})

export default useStyles
