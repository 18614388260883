import IMask from "imask"

export const formatPhoneNumber = (rawNumber: string): string => {
	let formattedPhoneNumber = rawNumber

	if (formattedPhoneNumber) {
		const phoneNumberMask = IMask.createMask({
			mask: [
				{
					mask: "(00) 00000-0000"
				},
				{
					mask: "(00) 0000-0000"
				}
			]
		})

		formattedPhoneNumber = phoneNumberMask.resolve(formattedPhoneNumber)
	}

	return formattedPhoneNumber
}

export const formatCPF = (rawCPF: string): string => {
	let formattedCPF = rawCPF

	if (formattedCPF) {
		const CPFMask = IMask.createMask({
			mask: [
				{
					mask: "000.000.000-00"
				}
			]
		})

		formattedCPF = CPFMask.resolve(formattedCPF)
	}

	return formattedCPF
}

export const formatCEP = (rawCEP: string): string => {
	let formattedCEP = rawCEP

	if (formattedCEP) {
		const CEPMask = IMask.createMask({
			mask: [
				{
					mask: "00000-000"
				}
			]
		})

		formattedCEP = CEPMask.resolve(formattedCEP)
	}

	return formattedCEP
}

export const formatCNPJ = (rawCNPJ: string): string => {
	let formattedCNPJ = rawCNPJ

	if (formattedCNPJ) {
		const CNPJMask = IMask.createMask({
			mask: [
				{
					mask: "​00.000.000/0000-00"
				}
			]
		})

		formattedCNPJ = CNPJMask.resolve(formattedCNPJ)
	}

	return formattedCNPJ
}
