import { useEffect } from "react"

const useDebounce = (
	handler: {
		(): void; func?: Promise<void>; waitFor?: number
	},
	watchedValue: unknown,
	delay: number | undefined
) => useEffect(() => {
	const isValid = watchedValue !== null && watchedValue !== undefined

	if (isValid) {
		const timeoutHandler = setTimeout(() => {
			handler()
		}, delay)
		return () => {
			clearTimeout(timeoutHandler)
		}
	}
	// eslint-disable-next-line
}, [watchedValue, delay])

export default useDebounce
