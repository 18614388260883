import React, { useState } from "react"
import { ThemeProvider, Snackbar } from "@material-ui/core"
import MuiAlert from "@material-ui/lab/Alert"

import theme from "@/styles/theme"

import { renderComponent } from "@/utils/node"

type NotificationProps = {
	message: string
	type?: "success" | "error" | "warning" | "info"
	onClose?: () => void
	onOpen?: () => void
}

type NotificationType = {
	success: (props: NotificationProps) => void
	error: (props: NotificationProps) => void
	warning: (props: NotificationProps) => void
	info: (props: NotificationProps) => void
}

const Notification: NotificationType & React.FC<NotificationProps> = (props) => {
	const { type, message, onClose, onOpen } = props

	const [visible, setVisible] = useState(true)

	const handleClose = (_: React.SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return
		}

		onClose && onClose()

		setVisible(false)
	}

	const handleOpen = () => {
		onOpen && onOpen()
	}

	return (
		<ThemeProvider theme={theme}>
			<Snackbar
				open={visible}
				autoHideDuration={5000}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right"
				}}
				onEntered={handleOpen}
			>
				<MuiAlert
					onClose={handleClose}
					elevation={1}
					variant="standard"
					severity={type}
				>
					{message}
				</MuiAlert>
			</Snackbar>
		</ThemeProvider>
	)
}

Notification.success = (props) => renderComponent(
	"notification",
	<Notification type="success" {...props} />
)

Notification.error = (props) => renderComponent(
	"notification",
	<Notification type="error" {...props} />
)

Notification.warning = (props) => renderComponent(
	"notification",
	<Notification type="warning" {...props} />
)

Notification.info = (props) => renderComponent(
	"notification",
	<Notification type="info" {...props} />
)

export default Notification
