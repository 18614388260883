export default {
	number_of_characters_greater_than_10: "O número de caracteres não pode ser superior a 10.",
	number_of_characters_greater_than_30: "O número de caracteres não pode ser superior a 30.",
	number_of_characters_greater_than_45: "O número de caracteres não pode ser superior a 45.",
	number_of_characters_greater_than_50: "O número de caracteres não pode ser superior a 50.",
	number_of_characters_greater_than_100: "O número de caracteres não pode ser superior a 100.",
	number_of_characters_greater_than_255: "O número de caracteres não pode ser superior a 255.",

	number_of_characters_less_than_4: "O número de caracteres não pode ser inferior a 4.",
	number_of_characters_less_than_10: "O número de caracteres não pode ser inferior a 10."
}
