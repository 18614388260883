export default {
	field_cannot_be_empty: "O campo não pode ser vazio.",
	invalid_format_email: "Email é inválido.",
	user_email_not_exist: "Usuário não cadastrado.",
	user_email_exist: "Usuário já cadastrado no sistema.",
	password_is_invalid: "Senha inválida.",
	does_not_belong_to_this_organization: "Não pertence a essa organização.",
	tax_document_code_is_invalid: "O CPF é inválido.",
	postal_code_is_invalid: "O CEP é inválido.",
	phone_number_is_invalid: "O celular é inválido.",
	home_number_is_invalid: "O telefone é inválido.",
	must_accept_the_terms_of_use_to_continue: "É obrigatório aceitar os termos de uso para continuar.",
	unconfirmed_email: "O email não confirmado, favor verificar sua caixa de entrada.",
	email_sent_in_the_last_24_hours: "Você já solicitou recuperação de senha nas últimas 24h, favor verificar sua caixa de e-mail.",
	password_token_is_not_valid_to_this_email: "O token utilizado não é válido para esse email.",
	this_access_code_exist: "Um código de acesso já existe com esse nome.",
	this_social_name_exist: "A razão social já foi cadastrada, tente outra.",
	this_cnpj_exist: "O CNPJ já foi cadastrado, tente outro.",
	this_cnpj_is_invalid: "O CNPJ é inválido.",
	this_path_url_exist: "Essa path URL já existe.",
	this_video_link_is_invalid: "O link do Youtube está inválido.",
	this_link_is_invalid: "O link está inválido.",
	this_title_exist: "Esse título já existe."
}
