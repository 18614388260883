import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Button, Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, TextField, Typography } from "@material-ui/core"

import { ptBR } from "date-fns/locale"
import DateFnsUtils from "@date-io/date-fns"
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"

import ApiService from "@/services/api"

import { Divider, Loading, Notification } from "@/components"

import UserService from "@/services/UserService"
import IntegrationService, { CourseOfInterestOptionsProps, CityAndStateOptionsProps } from "@/services/IntegrationService"
import AdditionalInfoSkeleton from "@/skeletons/AdditionalInfoSkeleton"

import useValidation from "@/hooks/useValidation"
import { formatCPF, formatPhoneNumber } from "@/utils/mask"

import useCustomStyles from "@/styles/custom"
import useDidMount from "@/hooks/useDidMount"
import { Autocomplete } from "@material-ui/lab"
import useDebounce from "@/hooks/useDebounce"
import { RootStateProps } from "@/reducers"

type CompleteSignUpDataProps = {
	taxDocumentCode?: string | null
	birthDate?: Date | null
	phone?: string | null
	cityAndState?: string | null
	courseOfInterest?: string | null
}

const CompleteSignUpModal = () => {
	const customClasses = useCustomStyles()

	const dispatch = useDispatch()
	const { sponsorConfig } = useSelector(
		(state: RootStateProps) => state.UserConfig
	)

	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [openModalDialog, setOpenModalDialog] = useState<boolean>(
		false
	)
	const [isOpenModalCheckbox, setIsOpenModalCheckbox] = useState<boolean>(
		false
	)

	const [completeSignUpData, setCompleteSignUpData] = useState<CompleteSignUpDataProps>({
		taxDocumentCode: null,
		birthDate: null,
		phone: null,
		courseOfInterest: null,
		cityAndState: null
	})

	const [openCourseOfInterestAutoComplete, setOpenCourseOfInterestAutoComplete] = useState<boolean>(false)
	const [courseOfInterestSearch, setCourseOfInterestSearch] = useState<string | null>()
	const [courseOfInterestOptions, setCourseOfInterestOptions] = useState<CourseOfInterestOptionsProps[]>([])
	const [loadingCourseOfInterestOptions, setLoadingCourseOfInterestOptions] = useState<boolean>(false)

	const [openCityAndStateAutoComplete, setOpenCityAndStateAutoComplete] = useState<boolean>(false)
	const [cityAndStateSearch, setCityAndStateSearch] = useState<string| null>(null)
	const [cityAndStateOptions, setCityAndStateOptions] = useState<CityAndStateOptionsProps[]>([])
	const [loadingCityAndStateOptions, setLoadingCityAndStateOptions] = useState<boolean>(false)

	const {
		triggerValidation,
		triggerUndeterminedValidation,
		clearValidation,
		validation
	} = useValidation()

	const getCourseOptions = async () => {
		setLoadingCourseOfInterestOptions(true)

		if (courseOfInterestSearch) {
			try {
				const data = await IntegrationService.getCourseOptions(courseOfInterestSearch)

				setCourseOfInterestOptions(data)
			} catch (error) {
				triggerValidation(error)
			}
		}

		setLoadingCourseOfInterestOptions(false)
	}

	useDebounce(
		async () =>	await getCourseOptions(),
		courseOfInterestSearch,
		1000
	)

	const handleCityAndStateChange = (cityAndState: string) => {
		clearValidation("cityAndState")

		setCityAndStateSearch(cityAndState)
	}

	const getCityOptions = async () => {
		setLoadingCityAndStateOptions(true)

		if (cityAndStateSearch) {
			try {
				const data = await IntegrationService
					.getCityOptions(cityAndStateSearch)

				setCityAndStateOptions(data)
			} catch (error) {
				triggerValidation(error)
			}
		}

		setLoadingCityAndStateOptions(false)
	}

	useDebounce(
		async () =>	await getCityOptions(),
		cityAndStateSearch,
		1000
	)

	const handleCourseOfInterestChange = (courseName: string) => {
		clearValidation("courseOfInterest")

		setCourseOfInterestSearch(courseName)
	}

	const handleCompleteSignUpDataSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		try {
			const { data } = await ApiService.put("user/change-account", completeSignUpData)

			dispatch({
				type: "USER_DATA/SET_APP_CONFIG",
				sponsorConfig: {
					additionalInfo: {
						requiredInfoIsOk: true,
						optionalInfoIsOk: true
					}
				}
			})
			dispatch({
				type: "USER_DATA/SET_USER_INFO",
				userInfo: {
					phone: data.phone,
					taxDocumentCode: data.taxDocumentCode,
					birthDate: data.birthDate,
					postalCode: data.postalCode,
					cityAndState: data.cityAndState,
					courseOfInterest: data.courseOfInterest
				}
			})

			UserService.setClosableCompleteSignUp(data.id, isOpenModalCheckbox)
			setOpenModalDialog(false)

			Notification.success({
				message: "Alterações realizadas com sucesso!"
			})
		} catch (error) {
			triggerValidation(error)
		}
	}

	const handleCompleteSignUpDataChange = (type: keyof CompleteSignUpDataProps, value: string | Date) => {
		clearValidation(type)

		setCompleteSignUpData((currentState) => ({
			...currentState,
			[type]: value
		}))
	}

	const handleIsOpenModalCheckToggle = (isOpen: boolean) => {
		setIsOpenModalCheckbox(isOpen)
	}

	const initializeValues = async () => {
		setIsLoading(true)
		try {
			const { data } = await ApiService.get("/user/info")

			if (data.userInfo.userType === "E") {
				setCompleteSignUpData({
					birthDate: data.userInfo.birthDate,
					courseOfInterest: data.userInfo.courseOfInterest,
					phone: data.userInfo.phone,
					cityAndState: (data.userInfo?.cityName && data.userInfo?.stateUF)
						? `${data.userInfo.cityName} , ${data.userInfo?.stateUF}`
						: null,
					taxDocumentCode: data.userInfo.taxDocumentCode
				})

				setCourseOfInterestSearch(data.userInfo.courseOfInterest || null)
				setCityAndStateSearch((data.userInfo?.cityName && data.userInfo?.stateUF)
					? `${data.userInfo.cityName} , ${data.userInfo?.stateUF}`
					: null)

				const isClosable = UserService.isClosableCompleteSignUp(data.userInfo.id)

				const isOpenCompleteSignUp = !data.additionalInfo?.requiredInfoIsOk ||
			(
				!isClosable &&
				!data.additionalInfo?.optionalInfoIsOk
			)

				setOpenModalDialog(isOpenCompleteSignUp)
				setIsOpenModalCheckbox(isClosable)
			}
		} catch (error) {
			triggerUndeterminedValidation(error)
		}
		setIsLoading(false)
	}

	useDidMount(() => {
		initializeValues()
	})

	return (
		<Dialog
			open={openModalDialog}
			PaperProps={{
				className: customClasses.paperDialog
			}}
			maxWidth="sm"
			fullWidth
		>
			<DialogTitle>
				<Typography align="center" variant="h3" color="primary">
					Completar cadastro
				</Typography>

				<Divider size={1} orientation="horizontal"/>

				<Typography align="center">
					Complete as informações abaixo para continuar
				</Typography>
			</DialogTitle>

			<DialogContent>
				<Loading
					loading={isLoading}
					customLoadingElement={AdditionalInfoSkeleton}
				>
					<Grid
						container
						spacing={1}
						justify="center"
						component='form'
						onSubmit={handleCompleteSignUpDataSubmit}
					>
						{(
							sponsorConfig.registerCpfRequired ||
							sponsorConfig.registerCpfVisible
						) && (
							<Grid item xs={12}>
								<TextField
									tabIndex={1}
									id="complete_sign-up_input_cpf"
									label="CPF"
									name="taxDocumentCode"
									type="string"
									placeholder="Ex.: 999.999.999-99"
									value={completeSignUpData.taxDocumentCode}
									onChange={({ target }) =>
										handleCompleteSignUpDataChange(
											"taxDocumentCode",
											formatCPF(target.value)
										)}
									variant="outlined"
									fullWidth
									helperText={
										validation.taxDocumentCode ||
									`* ${
										sponsorConfig.registerCpfRequired
											? "Obrigatório"
											: "Opcional"
									}`
									}
									error={Boolean(validation.taxDocumentCode)}
								/>
							</Grid>
						)}

						{(
							sponsorConfig.registerBirthDateRequired ||
							sponsorConfig.registerBirthDateVisible
						) && (
							<Grid item xs={12}>
								<MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
									<KeyboardDatePicker
										tabIndex={2}
										label="Data de nascimento"
										id="complete_sign-up_input_birth-date"
										margin="none"
										inputVariant="outlined"
										format="dd/MM/yyyy"
										animateYearScrolling
										views={["year", "month", "date"]}
										value={completeSignUpData.birthDate || null}
										color="primary"
										className={customClasses.datePicker}
										InputProps={{
											className: customClasses.datePickerInput
										}}
										cancelLabel="Cancelar"
										invalidDateMessage="Data inválida."
										onChange={
											(date) => handleCompleteSignUpDataChange(
												"birthDate",
												date as Date
											)
										}
										maxDate={new Date()}
										maxDateMessage="A data informada é inválida."
										error={Boolean(validation.birthDate)}
										helperText={validation.birthDate ||
										`* ${
											sponsorConfig.registerBirthDateRequired
												? "Obrigatório"
												: "Opcional"
										}`}
									/>
								</MuiPickersUtilsProvider>
							</Grid>
						)}

						{(
							sponsorConfig.registerPhoneRequired ||
							sponsorConfig.registerPhoneVisible
						) && (
							<Grid item xs={12}>
								<TextField
									tabIndex={3}
									id="complete_sign-up_input_phone"
									label="Celular"
									name="phone"
									type="string"
									placeholder="Ex.: (99) 99999-9999"
									value={completeSignUpData.phone}
									onChange={({ target }) =>
										handleCompleteSignUpDataChange(
											"phone",
											formatPhoneNumber(target.value)
										)}
									variant="outlined"
									fullWidth
									helperText={
										validation.phone ||
									`* ${
										sponsorConfig.registerPhoneRequired
											? "Obrigatório"
											: "Opcional"
									}`
									}
									error={Boolean(validation.phone)}
								/>
							</Grid>
						)}

						{(
							sponsorConfig.registerStateAndCityRequired ||
							sponsorConfig.registerStateAndCityVisible
						) && (
							<Grid item xs={12}>
								<Autocomplete
									id="autocomplete-city-name"
									inputValue={cityAndStateSearch || undefined}
									open={openCityAndStateAutoComplete}
									onOpen={() => {
										setOpenCityAndStateAutoComplete(true)
									}}
									onClose={() => {
										setOpenCityAndStateAutoComplete(false)
									}}
									getOptionSelected={(option, value) =>
										option.cityState === value.cityState
									}
									getOptionLabel={(option) => option.cityState}
									options={cityAndStateOptions}
									loading={loadingCityAndStateOptions}
									noOptionsText={"Sem opções de cidade"}
									loadingText="Procurando cidades..."
									placeholder="Digite a cidade"
									onChange={
										(event: any, option: CityAndStateOptionsProps | null) => {
											if (option) {
												handleCompleteSignUpDataChange(
													"cityAndState",
													option?.cityState
												)
											}
										}}
									classes={{
										input: customClasses.inputAutocomplete
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											tabIndex={4}
											name="cityAndState"
											label="Cidade"
											variant="outlined"
											onChange={({ target }) =>
												handleCityAndStateChange(
													target.value
												)}
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<>
														{loadingCityAndStateOptions &&
															<CircularProgress color="inherit" size={20} />
														}
														{params.InputProps.endAdornment}
													</>
												)
											}}
											helperText={
												validation.cityAndState ||
											`* ${
												sponsorConfig.registerStateAndCityRequired
													? "Obrigatório"
													: "Opcional"
											}`
											}
											error={Boolean(validation.cityAndState)}
										/>
									)}
								/>
							</Grid>
						)}

						{(
							sponsorConfig.courseOfInterestRequired ||
							sponsorConfig.courseOfInterestVisible
						) && (
							<Grid item xs={12}>
								<Autocomplete
									id="autocomplete-course-of-interest"
									inputValue={
										courseOfInterestSearch ||
										undefined
									}
									open={openCourseOfInterestAutoComplete}
									onOpen={() => {
										setOpenCourseOfInterestAutoComplete(true)
									}}
									onClose={() => {
										setOpenCourseOfInterestAutoComplete(false)
									}}
									getOptionSelected={(option, value) =>
										option.baseCourseName === value.baseCourseName
									}
									getOptionLabel={(option) => option.baseCourseName}
									options={courseOfInterestOptions}
									loading={loadingCourseOfInterestOptions}
									noOptionsText={"Sem opções de curso"}
									loadingText="Procurando cursos..."
									placeholder="Digite o curso de interesse"

									onChange={
										(event: any, option: CourseOfInterestOptionsProps | null) => {
											if (option) {
												handleCourseOfInterestChange(option?.baseCourseName)

												handleCompleteSignUpDataChange(
													"courseOfInterest",
													option?.baseCourseName
												)
											}
										}}
									classes={{
										input: customClasses.inputAutocomplete
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											tabIndex={5}
											name="courseOfInterest"
											label="Curso de interesse"
											variant="outlined"
											onChange={({ target }) =>
												handleCourseOfInterestChange(
													target.value
												)}
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<>
														{loadingCourseOfInterestOptions &&
															<CircularProgress color="inherit" size={20} />
														}
														{params.InputProps.endAdornment}
													</>
												)
											}}
											helperText={
												validation.courseOfInterest ||
											`* ${
												sponsorConfig.courseOfInterestRequired
													? "Obrigatório"
													: "Opcional"
											}`
											}
											error={Boolean(validation.courseOfInterest)}
										/>
									)}
								/>
							</Grid>
						)}

						<Grid item xs={12}>
							<FormControlLabel
								control={
									<Checkbox
										id="checkbox-close-modal"
										name="expiresDateCodeUnlimited"
										checked={isOpenModalCheckbox}
										onChange={
											() => handleIsOpenModalCheckToggle(!isOpenModalCheckbox)
										}
									/>
								}
								label={
									<Typography variant="body1">
										Não desejo que esta mensagem apareça novamente.
									</Typography>
								}
							/>
						</Grid>

						<Grid item xs={12}>
							<Grid container justify="center">
								<Button
									type="submit"
									variant="contained"
									color="primary"
								>
									Salvar alterações
								</Button>
							</Grid>
						</Grid>

					</Grid>

				</Loading>

			</DialogContent>
		</Dialog>
	)
}

export default CompleteSignUpModal
