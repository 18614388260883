import React, { Fragment } from 'react'
import { connect } from 'react-redux'

const Footer = (props) => {
    return <Fragment></Fragment>
}
const mapStateToProps = (state) => ({
    footerFixed: state.ThemeOptions.footerFixed,
    footerShadow: state.ThemeOptions.footerShadow,
    footerBgTransparent: state.ThemeOptions.footerBgTransparent,
})

export default connect(mapStateToProps)(Footer)
