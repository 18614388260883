import React, { useState, useEffect } from "react"
import { ChromePicker } from "react-color"

type ColorPickerProps = {
	selectedColor: string
	onChange: (hexColor: string) => void
}

const ColorPicker: React.FC<ColorPickerProps> = (props) => {
	const { selectedColor, onChange } = props

	const [currentColor, setCurrentColor] = useState(selectedColor)

	const handleChange = (hexColor: string) => {
		setCurrentColor(hexColor)
	}

	const onChangeCompleted = (hexColor: string) => {
		onChange(hexColor)
	}

	useEffect(() => {
		setCurrentColor(selectedColor)
	}, [selectedColor])

	return (
		<ChromePicker
			disableAlpha
			color={currentColor}
			onChange={({ hex }) => handleChange(hex)}
			onChangeComplete={({ hex }) => onChangeCompleted(hex)}
		/>
	)
}

export default ColorPicker
