import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons"

import UserService from "@/services/UserService"
import { Grid, Menu, MenuItem, Avatar, IconButton, Typography } from "@material-ui/core"
import { RootStateProps } from "@/reducers"

import useStyles from "@/layouts/layout-components/HeaderUserbox/styles"

const HeaderUserbox = () => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

	const history = useHistory()
	const classes = useStyles()

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const { userInfo, sponsorConfig } = useSelector(
		(state: RootStateProps) => state.UserConfig
	)
	const { photoUrl, displayName } = userInfo
	const { baseUrlLanding } = sponsorConfig

	const handleLogout = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault()

		UserService.logout()

		if (!baseUrlLanding) {
			const route = userInfo.userType === "A" ? "/admin" : "/"
			history.push(route)
		} else {
			window.location.href = baseUrlLanding
		}
	}
	return (

		<Grid container alignItems="center" className={classes.container}>
			<Grid item>
				<Grid container spacing={1} alignItems="center">
					<Grid item>
						{photoUrl ? (
							<Avatar variant="rounded" className={classes.avatar} src={photoUrl} alt="Foto de perfil" />
						) : (
							<Avatar className={classes.avatar} variant="rounded" >
								{displayName?.charAt(0)}
							</Avatar>
						)}

					</Grid>

					<Grid item>
						<Typography variant="body1" color="primary" className={classes.nameText}>
							{displayName}
						</Typography>
					</Grid>
				</Grid>
			</Grid>

			<Grid item>
				<IconButton color="primary" onClick={handleClick}>
					<ExpandMoreIcon/>
				</IconButton>
			</Grid>

			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem
					component={Link}
					to="/minha-conta"
					onClick={handleClose}
				>
					Minha Conta
				</MenuItem>
				<MenuItem
					component="a"
					href="#"
					onClick={(e: React.MouseEvent<HTMLElement>) => handleLogout(e)}
				>
					Sair do sistema
				</MenuItem>
			</Menu>
		</Grid>
	)
}

export default HeaderUserbox
