import ThemeOptions from "@/reducers/ThemeOptions"
import UserConfig from "@/reducers/UserConfig"

import { UserType } from "@/protocols/UserProtocol"

export type RootStateProps = {
	UserConfig: {
		userInfo: {
			id: number
			displayName: string
			email: string
			photoUrl: string
			phone: string
			userType: UserType
			taxDocumentCode: string
			birthDate: Date
			postalCode: string
			courseOfInterest: string
		},
		sponsorConfig: {
			pathUrl: string,
			trademarkUrl: string
			sponsorConfigId: number
			backgroundColor: string,
			registerCpfRequired: boolean,
			registerCpfVisible: boolean,
			registerBirthDateRequired: boolean,
			registerBirthDateVisible: boolean,
			registerPhoneRequired: boolean,
			registerPhoneVisible: boolean,
			registerStateAndCityRequired: boolean,
			registerStateAndCityVisible: boolean,
			courseOfInterestRequired: boolean,
			courseOfInterestVisible: boolean,
			additionalInfo: {
				requiredInfoIsOk: boolean,
				optionalInfoIsOk: boolean
			}
			baseUrlLanding: string
		}
	},
}

export default {
	ThemeOptions,
	UserConfig
}
