import StorageService from "@/services/StorageService"

class UserService {
	private auth_token_key: string
	private closable_complete_sign_up_key: string

	constructor () {
		this.auth_token_key = "auth_token"
		this.closable_complete_sign_up_key = "closable_add_info"
	}

	getAuthToken = (): string | null => {
		return StorageService.get(this.auth_token_key)
	}

	isAuthenticated = (): boolean => {
		return Boolean(StorageService.get(this.auth_token_key))
	}

	login = (token: string): void => {
		StorageService.set(this.auth_token_key, token)
	}

	logout = (): void => {
		StorageService.delete(this.auth_token_key)
	}

	isClosableCompleteSignUp = (userId: number): boolean => {
		const isClosable = StorageService.get(`${this.closable_complete_sign_up_key}_${userId}`)

		return Boolean(isClosable)
	}

	setClosableCompleteSignUp = (userId: number, isClose: boolean): void => {
		StorageService.set(`${this.closable_complete_sign_up_key}_${userId}`, isClose)
	}
}

export default new UserService()
