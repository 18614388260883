import axios from "axios"
import axiosRetry from "axios-retry"
import UserService from "@/services/UserService"
import { enemVip } from "@/config/Url"

const ApiService = axios.create({
	baseURL: enemVip.apiUrl
})

axiosRetry(ApiService, {
	retries: 5,
	retryDelay: (retryCount) => 1000 * retryCount
})

ApiService.interceptors.request.use((config) => {
	const token = UserService.getAuthToken()

	if (token) {
		config.headers["x-auth-token"] = token
	} else {
		config.headers["Content-Type"] = "application/json"
	}
	return config
})

export default ApiService

export const apiZipCode = axios.create({
	baseURL: "https://viacep.com.br/ws"
})
