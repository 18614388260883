import { useDispatch } from "react-redux"

import ApiService from "@/services/api"
import history, { pathName } from "@/services/history"
import UserService from "@/services/UserService"
import { Notification } from "@/components"

export const useCommonApi = () => {
	const dispatch = useDispatch()

	const loadUserData = async () => {
		UserService.isAuthenticated()
			? await ApiService.get("/user/info")
				.then(({ data }) => {
					const {
						id,
						displayName,
						email,
						photoUrl,
						phone,
						userType,
						taxDocumentCode,
						birthDate,
						postalCode,
						cityName,
						stateUF,
						courseOfInterest,
						accessCodeId
					} = data.userInfo
					const {
						id: sponsorConfigId,
						trademarkUrl,
						trademarkUrlLanding,
						backgroundColor,
						pathUrl,
						registerCpfRequired,
						registerCpfVisible,
						registerBirthDateRequired,
						registerBirthDateVisible,
						registerPhoneRequired,
						registerPhoneVisible,
						registerStateAndCityRequired,
						registerStateAndCityVisible,
						courseOfInterestRequired,
						courseOfInterestVisible,
						writingCourse,
						baseUrlLanding
					} = data.sponsorConfig
					dispatch({
						type: "USER_DATA/SET_USER_INFO",
						userInfo: {
							id,
							displayName,
							email,
							photoUrl,
							phone,
							userType,
							taxDocumentCode,
							birthDate,
							postalCode,
							cityAndState:
									cityName && stateUF
										? `${cityName} , ${stateUF}`
										: null,
							courseOfInterest,
							accessCodeId
						}
					})
					dispatch({
						type: "USER_DATA/SET_APP_CONFIG",
						sponsorConfig: {
							sponsorConfigId,
							backgroundColor: backgroundColor,
							trademarkUrl: trademarkUrl,
							trademarkUrlLanding: trademarkUrlLanding,
							pathUrl: pathUrl,
							registerCpfRequired,
							registerCpfVisible,
							registerBirthDateRequired,
							registerBirthDateVisible,
							registerPhoneRequired,
							registerPhoneVisible,
							registerStateAndCityRequired,
							registerStateAndCityVisible,
							courseOfInterestRequired,
							courseOfInterestVisible,
							additionalInfo: data.additionalInfo,
							writingCourse,
							baseUrlLanding
						}
					})
					if (userType === "E") {
						history.push("/novo-simulado")
					} else {
						history.push("/dashboard")
					}
				})
				.catch((e) => {
					pathName[1] === "enem-vip" || pathName[1] === ""
						? history.push("/admin")
						: history.push("/signup")

					Notification.error({
						message: e.response.data.message
					})
				})
			: pathName[1] === "enem-vip" || pathName[1] === ""
				? history.push("/admin")
				: history.push("/signup")
	}

	return {
		loadUserData
	}
}
