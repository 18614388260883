import React, { useState } from "react"

import { Link } from "react-router-dom"

import {
	Button,
	Grid,
	Link as LinkMui,
	TextField,
	CircularProgress,
	Typography,
	InputAdornment,
	IconButton
} from "@material-ui/core"
import {
	Visibility as ShowPassword,
	VisibilityOff as HiddenPassword
} from "@material-ui/icons"

import ApiService from "@/services/api"
import history, { pathName } from "@/services/history"

import CommonAuthStudent from "@/pages/Auth/components/Container"
import { Divider, Notification } from "@/components"
import useValidation from "@/hooks/useValidation"
import { formatEmail } from "@/utils/sanitization"

type ResultSimulateProps = {
	match: {
		params: {
			token: string
		}
	}
}

type ResetDataProps = {
	email: string
	password: string
}

const RequestPassword: React.FC<ResultSimulateProps> = (props) => {
	const { match } = props

	const {
		validation,
		clearValidation,
		triggerValidation
	} = useValidation()

	const [resetData, setResetData] = useState<ResetDataProps>({
		email: "",
		password: ""
	})
	const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
	const [visiblePassword, setVisiblePassword] = useState<boolean>(false)

	const submitResetData = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		setLoadingSubmit(true)

		try {
			await ApiService.patch("/auth/forgot-password/reset", {
				email: resetData.email,
				token: match.params.token,
				password: resetData.password,
				confirmPassword: resetData.password,
				pathUrl: pathName[1]
			})

			Notification.success({
				message: "Senha alterada!",
				onClose: () => history.push("/")
			})
		} catch (error) {
			triggerValidation(error)
		}

		setLoadingSubmit(false)
	}

	const handleResetDataChange = (type: keyof ResetDataProps, value: string) => {
		clearValidation(type)

		setResetData((currentState) => ({
			...currentState,
			[type]: value
		}))
	}

	const handleClickShowPassword = () => {
		setVisiblePassword(!visiblePassword)
	}

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault()
	}

	return (
		<CommonAuthStudent>
			<Grid
				spacing={1}
				container
				justify="center"
				component="form"
				onSubmit={submitResetData}
			>
				<Grid item xs={10}>
					<TextField
						id="input-request-password-email"
						label="Email"
						tabIndex={1}
						placeholder="seunome@seuemail.com"
						name="email"
						value={resetData.email}
						onChange={({ target }) =>
							handleResetDataChange(
								"email",
								formatEmail(target.value)
							)
						}
						variant="outlined"
						fullWidth
						helperText={validation.email}
						error={Boolean(validation.email)}
					/>
				</Grid>

				<Grid item xs={10}>
					<TextField
						id="input-password"
						label="Nova senha"
						tabIndex={2}
						name="password"
						value={resetData.password}
						type={visiblePassword ? "text" : "password"}
						onChange={
							({ target }) => handleResetDataChange(
								"password",
								target.value
							)
						}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="Tornar senha visível"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{visiblePassword ? <ShowPassword /> : <HiddenPassword />}
									</IconButton>
								</InputAdornment>
							)
						}}
						fullWidth
						variant="outlined"
						helperText={validation.password}
						error={Boolean(validation.password)}
					/>
				</Grid>

				<Divider size={1} orientation="horizontal" />

				<Grid item xs={10}>
					<Button
						tabIndex={-1}
						color="primary"
						type="submit"
						variant="contained"
						endIcon={
							loadingSubmit &&
							<CircularProgress size={16} color="inherit"/>
						}
						disabled={loadingSubmit}
						fullWidth
					>
						Alterar senha
					</Button>
				</Grid>

				<Divider size={1.5} orientation="horizontal" />

				<Grid item xs={12}>
					<Typography
						align="center"
					>
						<LinkMui
							component={Link}
							to="/"
							color="inherit"
							underline="none"
						>
							Voltar para página inicial
						</LinkMui>
					</Typography>
				</Grid>
			</Grid>
		</CommonAuthStudent>
	)
}

export default RequestPassword
