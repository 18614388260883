import React from "react"
import { Paper, Typography } from "@material-ui/core"
import {
	Timeline as TimelineMui,
	TimelineConnector,
	TimelineContent,
	TimelineDot,
	TimelineItem,
	TimelineProps,
	TimelineSeparator
} from "@material-ui/lab"
import useCustomStyles from "@/styles/custom"

export type TimelineDataValuesProps ={
	icon?: React.ReactNode
	title: string
	content: string
}

type TimelineComponentProps = {
	dataValues: TimelineDataValuesProps[]
	align: TimelineProps["align"]
}

const Timeline: React.FC<TimelineComponentProps> = (props) => {
	const { dataValues } = props

	const customClasses = useCustomStyles()

	const TimelineItems = dataValues.map((timelineItem, index) =>
		<TimelineItem key={index}>
			<TimelineSeparator>
				{timelineItem.icon
					? <TimelineDot>
						{timelineItem.icon}
					</TimelineDot> : <TimelineDot/>
				}
				<TimelineConnector />
			</TimelineSeparator>
			<TimelineContent>
				<Paper elevation={3} className={customClasses.paperTimeline}>
					<Typography variant="h6" component="h1">
						{timelineItem.title}
					</Typography>
					<Typography>{timelineItem.content}</Typography>
				</Paper>
			</TimelineContent>
		</TimelineItem>
	)

	return <TimelineMui align="alternate">
		{TimelineItems}
	</TimelineMui>
}

export default Timeline
