import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
	container: {},
	nameText: {
		[theme.breakpoints.down("sm")]: {
			fontSize: "13px !important"
		}
	},
	avatar: {
		width: "48px !important",
		height: "48px !important",
		background: colors.palette.primary,
		fontWeight: 500,
		fontSize: "16px !important",
		[theme.breakpoints.down("sm")]: {
			width: "32px !important",
			height: "32px !important"
		}
	}
}))

export default useStyles
