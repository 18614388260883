import React, { useState } from "react"
import { useDropzone } from "react-dropzone"
import { Grid, Tooltip, Button, Box, Typography } from "@material-ui/core"
import {
	Delete as DeleteIcon,
	Edit as EditIcon,
	CloudUpload as UploadIcon
} from "@material-ui/icons"

import useStyles from "@/components/DropzoneImage/styles"

import DropzoneSkeleton from "@/skeletons/DropzoneSkeleton"
import Loading from "@/components/Loading"
import useCustomStyles from "@/styles/custom"

type DropzoneImageProps = {
	dataImg: string | null
	typeImage: string
	onChange: (file: File, formData: FormData, typeImage: any) => Promise<void>
	onDelete?: (typeImage: any) => void
}

const DropzoneImage: React.FC<DropzoneImageProps> = (props) => {
	const { onChange, onDelete, dataImg, typeImage } = props

	const [loading, setLoading] = useState(false)

	const classes = useStyles({
		isLoading: loading
	})
	const customClasses = useCustomStyles()

	const onDrop = async ([newFile]: File[]) => {
		setLoading(true)

		const formData = new FormData()

		formData.append("newImage", newFile)

		await onChange(newFile, formData, typeImage)

		setLoading(false)
	}

	const deleteImage = () => {
		onDelete && onDelete(typeImage)
	}

	const { getRootProps, getInputProps } = useDropzone({ onDrop })
	return (
		<Loading loading={loading} customLoadingElement={<DropzoneSkeleton />}>
			{dataImg ? (
				<Box className={classes.imageContainer}>

					<Grid
						container
						justify="space-between"
						className={classes.imageContent}
					>
						{onDelete && (
							<Grid item xs={6} style={{ textAlign: "center" }}>
								<Grid
									container
									justify="center"
									alignItems="center"
									style={{ height: "100%" }}
								>
									<Grid item>
										<Tooltip title="Excluir">
											<Button
												color="inherit"
												onClick={deleteImage}
												className={customClasses.dangerButton}
											>
												<DeleteIcon />
											</Button>
										</Tooltip>
									</Grid>
								</Grid>
							</Grid>
						)}

						<Grid item xs className={classes.imageWrapper}>
							<Box component="div" {...getRootProps()}
								className={classes.boxUploadImage}>
								<input
									{...getInputProps()}
									accept=".jpg, .jpeg, .png"
									type="file"
									hidden
								/>
								<Tooltip title="Alterar imagem">
									<Button
										color="inherit"
										className={customClasses.warningButton}
									>
										<EditIcon />
									</Button>
								</Tooltip>
							</Box>
						</Grid>
					</Grid>

					<img className={classes.image} src={dataImg} alt="Logomarca" />
				</Box>
			) : (
				<Box component="div" {...getRootProps()}>
					<Grid container direction="column" justify="center" spacing={1} className={classes.uploadTrademarkContainer}>
						<Grid item>
							<UploadIcon className={classes.iconUploadTrademark} />
						</Grid>

						<Grid item>
							<input
								{...getInputProps()}
								accept=".jpg, .jpeg, .png"
								type="file"
								hidden
							/>
							<Typography variant="caption" className={classes.textUploadTrademark}>
								Clique aqui para alterar
							</Typography>
						</Grid>
					</Grid>
				</Box>
			)}
		</Loading>
	)
}

export default DropzoneImage
