import React, { useState } from "react"

import {
	Container,
	Grid,
	Divider as DividerMui,
	useTheme,
	useMediaQuery
} from "@material-ui/core"

import { pathName } from "@/services/history"
import { useCommonApi } from "@/services/api/commonApi"
import api from "@/services/api"

import UserService from "@/services/UserService"

import { Notification, Divider, Portlet, Loading } from "@/components"

import useSocialMedia from "@/hooks/useSocialMedia"

import theme from "@/styles/theme"
import useAuthClasses from "@/pages/Auth/styles"
import EnemvipLogoImg from "@/assets/images/logo/enemvip-logo.svg"
import useDidMount from "@/hooks/useDidMount"
import useValidation from "@/hooks/useValidation"

type CommonAuthContainerProps = {
	containSocialMedia?: boolean;
};

const CommonAuthContainer: React.FC<CommonAuthContainerProps> = (props) => {
	const { containSocialMedia, children } = props

	const commonApi = useCommonApi()
	const authClasses = useAuthClasses()
	const { FacebookAuth, GoogleAuth } = useSocialMedia()
	const { parseError } = useValidation()
	const MDTheme = useTheme()

	const isMobileDevice = useMediaQuery(MDTheme.breakpoints.down("xs"))

	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [, setBaseUrl] = useState(null)
	const [logoImage, setLogoImage] = useState<string>(EnemvipLogoImg)

	const FacebookAuthComponent = FacebookAuth()
	const GoogleAuthComponent = GoogleAuth()

	const getBaseUrl = async () => {
		try {
			const pathUrl = pathName[1]

			const { data } = await api.get(
				`sponsor-config/landing-page/${pathUrl}`
			)

			setBaseUrl(data.baseUrlLanding)

			data?.trademarkUrlLanding &&
				setLogoImage(data?.trademarkUrlLanding)
		} catch (error) {
			Notification.error({
				message:
					"Error ao buscar informações da página. Tente novamente!"
			})
		}
	}

	const checkToken = async () => {
		const token = pathName[3]

		if (token && token?.includes("token")) {
			try {
				const { data: tokenUser } = await api.patch(
					`user/activate/${token.replace("token=", "")}`
				)

				UserService.login(tokenUser)

				commonApi.loadUserData()

				Notification.success({
					message: "Conta ativada. Continue o login!"
				})
			} catch (error) {
				const responseError = parseError(error)

				let errorMessage = "Algo deu errado..."

				if (responseError.errorName === "token_invalid_or_expired") {
					errorMessage = "Token é inválido ou expirado..."
				}

				Notification.error({
					message: errorMessage
				})
			}
		}
	}

	const initialize = async () => {
		setIsLoading(true)

		await getBaseUrl()
		await checkToken()

		setIsLoading(false)
	}

	useDidMount(() => {
		initialize()
	})

	return (
		<Container maxWidth="sm" className={authClasses.container}>
			<Grid container justify="center" alignContent="center">
				<Loading loading={isLoading}>
					<Grid
						item
						xs={12}
						sm={10}
						className={authClasses.wrapperAuth}
					>
						<Portlet
							elevation={isMobileDevice ? 0 : 4}
							style={{
								padding: theme.spacing(4, 1)
							}}
						>
							<img
								src={logoImage}
								alt="Enemvip - Logo"
								className={authClasses.logo}
							/>

							{containSocialMedia && (
								<>
									<Divider
										size={2}
										orientation="horizontal"
									/>

									<Grid container justify="center">
										<Grid item xs={10}>
											{FacebookAuthComponent}
										</Grid>

										<Divider
											size={2}
											orientation="horizontal"
										/>

										<Grid item xs={10}>
											{GoogleAuthComponent}
										</Grid>
									</Grid>

									<Divider
										size={2}
										orientation="horizontal"
									/>

									<DividerMui />
								</>
							)}

							<Divider size={2} orientation="horizontal" />

							{children}
						</Portlet>
					</Grid>
				</Loading>
			</Grid>
		</Container>
	)
}

export default CommonAuthContainer
