import React, { ReactNode } from "react";
import {
	Card,
	CardHeader,
	CardContent,
	Avatar,
	Grid,
	Typography,
} from "@material-ui/core";
import {
	TrendingUp as TrendingUpIcon,
	TrendingDown as TrendingDownIcon,
	Equalizer as EqualizerIcon,
} from "@material-ui/icons";

import colors from "@/styles/colors";

import useStyles from "@/pages/Dashboard/components/CardCompareValues/styles";
import { Divider } from "@/components";

type OptionValuesProps = {
	[value: string]: {
		icon: ReactNode;
	};
};

export type CardCompareValuesProps = {
	icon: ReactNode;
	title: string;
	dataValues: {
		lastValue?: number;
		percentageCompare?: number;

		currentValue: number;
		referenceTime: string;
	};
};

const optionsValues: OptionValuesProps = {
	equal: {
		icon: <EqualizerIcon style={{ color: colors.palette.warning }} />,
	},
	higher: {
		icon: <TrendingUpIcon style={{ color: colors.palette.confirm }} />,
	},
	less: {
		icon: <TrendingDownIcon style={{ color: colors.palette.error }} />,
	},
	default: {
		icon: <EqualizerIcon style={{ color: colors.palette.warning }} />,
	},
};

const CardCompareValues: React.FC<CardCompareValuesProps> = (props) => {
	const classes = useStyles();

	const { icon, title, dataValues } = props;

	const compareValues = (): keyof OptionValuesProps => {
		if (!dataValues.percentageCompare) return "default";

		return dataValues.percentageCompare === 0
			? "equal"
			: dataValues.percentageCompare > 0
			? "higher"
			: "less";
	};

	const optionValue = optionsValues[compareValues()] || optionsValues.default;

	return (
		<Card variant="outlined" className={classes.cardContainer}>
			<CardHeader
				avatar={
					<Avatar
						style={{
							backgroundImage:
								dataValues.referenceTime === "Total"
									? "linear-gradient(135deg, #237af3 10%, #7eb4ff 100%)"
									: "linear-gradient(135deg, #3B2667 10%, #BC78EC 100%)",
						}}
					>
						{icon}
					</Avatar>
				}
				title={title}
			/>

			<Divider size={1} orientation="horizontal" />

			<CardContent>
				<Grid container>
					<Grid item xs={12}>
						<Grid
							container
							spacing={1}
							justify="center"
							alignItems="center"
						>
							{dataValues.referenceTime !== "Total" && (
								<Grid item>
									{dataValues.percentageCompare !== 0 &&
										optionValue.icon}
								</Grid>
							)}

							<Grid item>
								<Typography
									component="p"
									className={classes.currentValueText}
								>
									<b>{dataValues.currentValue}</b>{" "}
									{dataValues.currentValue > 1
										? "Usuários"
										: "Usuário"}
								</Typography>
							</Grid>
						</Grid>
					</Grid>

					<Divider size={2} orientation="horizontal" />

					<Grid item xs={12}>
						{"lastValue" in dataValues ? (
							<>
								{optionValue && (
									<Typography align="center">
										<b>{dataValues.percentageCompare}%</b>

										{` - Comparação ao ${dataValues?.referenceTime?.toLowerCase()} passado`}
									</Typography>
								)}

								<Divider size={1} orientation="horizontal" />

								<Typography
									variant="h4"
									component="p"
									align="center"
									style={{
										fontSize: "calc(.95rem * 1)",
									}}
								>
									Valor anterior: {dataValues.lastValue}
								</Typography>
							</>
						) : (
							<>
								<Divider size={2} orientation="horizontal" />

								<Typography
									variant="h4"
									component="p"
									align="center"
									className={classes.footerText}
								>
									Cadastrados no sistema
								</Typography>
							</>
						)}
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

export default CardCompareValues;
