import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"

const useCustomStyles = makeStyles(theme => ({
	inlineText: {
		textOverflow: "ellipsis",
		whiteSpace: "pre-wrap",
		overflow: "hidden",
		maxWidth: "100%",
		display: "-webkit-box",
		WebkitBoxOrient: "vertical",
		WebkitLineClamp: 1,
		flex: 1
	},
	scrollBar: {
		"&::-webkit-scrollbar": {
			width: "6px !important",
			height: "6px !important"
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: "rgba(0, 0, 0, 0.2)"
		},
		"&::-webkit-scrollbar-track": {
			background: "hsla(0, 0%, 100%, 0.1)"
		}
	},
	uppercase: {
		textTransform: "uppercase"
	},
	datePicker: {
		width: "100%"
	},
	datePickerInput: {
		// "& .MuiOutlinedInput-input": {
		// 	padding: "12px 10px"
		// }
	},
	paperDialog: {
		borderRadius: theme.spacing(1),
		padding: theme.spacing(1)
	},
	paperTable: {
		width: "100%"
	},
	dangerButton: {
		color: colors.grayScale[11],
		background: colors.palette.error,
		"&:hover": {
			backgroundColor: colors.palette.error,
			opacity: 0.7,
			boxShadow: "none"
		}
	},
	confirmButton: {
		color: colors.grayScale[11],
		background: colors.palette.confirm,
		"&:hover": {
			backgroundColor: colors.palette.confirm,
			opacity: 0.7,
			boxShadow: "none"
		}
	},
	warningButton: {
		color: colors.grayScale[11],
		background: colors.palette.warning,
		"&:hover": {
			backgroundColor: colors.palette.warning,
			opacity: 0.7,
			boxShadow: "none"
		}
	},
	closeIcon: {
		position: "absolute",
		top: 0,
		right: 0
	},
	visuallyHiddenTable: {
		border: 0,
		clip: "rect(0 0 0 0)",
		height: 1,
		margin: -1,
		overflow: "hidden",
		padding: 0,
		position: "absolute",
		top: 20,
		width: 1
	},
	tableTitle: {
		fontWeight: 600,
		textTransform: "uppercase"
	},
	tableRowHover: {
		cursor: "pointer",
		textDecoration: "none !important"
	},
	notFoundText: {
		fontSize: "18px"
	},
	inputAutocomplete: {
		padding: "6px !important"
	},
	paperTimeline: {
		padding: theme.spacing(1, 2)
	}
}), {
	index: 1 // this attribute is necessary to ensure classes not being overriden
})

export default useCustomStyles
