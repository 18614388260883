import React, { useState } from "react"
import {
	Typography,
	Dialog,
	Button,
	DialogTitle,
	DialogActions as DialogActionsMui,
	Grid,
	DialogContent,
	IconButton,
	CircularProgress
} from "@material-ui/core"
import {
	Close as CloseIcon
} from "@material-ui/icons"

import { Divider } from "@/components"

import useCustomStyles from "@/styles/custom"

type DialogActionsProps = {
	isOpen: boolean

	title: string
	bodyContent: string

	cancelButtonText?: string
	onCancel?: () => void

	okButtonText?: string
	onOk: (() => Promise<void>) | (() => void)

	onClose: () => void
}

const DialogActions: React.FC<DialogActionsProps> = (props) => {
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const {
		isOpen,
		title,
		bodyContent,

		cancelButtonText,
		onCancel,

		okButtonText,
		onOk,

		onClose
	} = props

	const customClasses = useCustomStyles()

	const handleClose = () => {
		onClose()
	}

	const handleOk = async () => {
		setIsLoading(true)

		await onOk()

		handleClose()

		setIsLoading(false)
	}

	const handleCancel = () => {
		onCancel && onCancel()

		handleClose()
	}

	return (
		<Dialog
			open={isOpen}
			onClose={handleClose}
			PaperProps={{
				className: customClasses.paperDialog
			}}
			maxWidth="sm"
			fullWidth
		>
			<IconButton onClick={handleClose} className={customClasses.closeIcon}>
				<CloseIcon />
			</IconButton>

			<DialogTitle>
				<Typography align="center" variant="h3" color="primary">
					{title}
				</Typography>
			</DialogTitle>

			<Divider size={2} orientation="horizontal" />

			<DialogContent>
				<Typography align="center" variant="body2">
					{bodyContent}
				</Typography>
			</DialogContent>

			<Divider size={3} orientation="horizontal" />

			<DialogActionsMui>
				<Grid container spacing={3} alignItems="center" justify="center">
					<Grid item>
						<Button
							onClick={handleCancel}
						>
							{cancelButtonText || "Cancelar"}
						</Button>
					</Grid>

					<Grid item>

						<Button
							onClick={handleOk}
							color="primary"
							variant="contained"
							disabled={isLoading}
							endIcon={
								isLoading &&
								<CircularProgress size={16} color="inherit"/>
							}
						>
							{okButtonText || "OK"}
						</Button>
					</Grid>
				</Grid>
			</DialogActionsMui>
		</Dialog>
	)
}

export default DialogActions
