/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react"

import { Notification } from "@/components"
import Messages from "@/utils/responseMessages/messages"

export type ErrorType = {
	message: string
	response: {
		status: string
		data: {
			error: string
			codeMessages: Array<string>
		}
	}
}

export type ValidationType = {
	[key: string]: any
}

const parseError = (error: ErrorType) => {
	// eslint-disable-next-line
	const validationMessages = error?.response?.data?.codeMessages
	const errorMessage = error?.message
	const errorName = error?.response?.data?.error
	const errorStatus = error?.response?.status

	return {
		validationMessages,
		errorMessage,
		errorName,
		errorStatus: +errorStatus
	}
}

const useValidation = () => {
	const [validation, setValidation] = useState<ValidationType>({})

	// eslint-disable-next-line
	const triggerUndeterminedValidation = (error: ErrorType) => {
		Notification.error({ message: "Algo deu errado e não conseguimos identificar. Entre em contato." })
		if (process.env.NODE_ENV === "development") {
			// eslint-disable-next-line

		}
	}

	const triggerValidation = (error: ErrorType) => {
		const data = parseError(error)

		if (!data.validationMessages) {
			triggerUndeterminedValidation(error)
		} else {
			const validationMessages = data.validationMessages

			Object.keys(validationMessages)
				.forEach(function (key: any) {
					validationMessages[key] = Messages(validationMessages[key] as string)
				})

			setValidation((lastValidation) => ({
				...lastValidation,
				...validationMessages
			}))
		}
	}

	const addValidation = (newValidation: ValidationType) => {
		setValidation((lastValidation) => ({
			...lastValidation,
			...newValidation
		}))
	}

	const clearValidation = (key: string) => {
		if (key in validation) {
			setValidation((lastValidation) => {
				const updatedData: any = { ...lastValidation }

				delete updatedData[key]

				return updatedData
			})
		}
	}

	const clearAllValidations = () => {
		setValidation({})
	}

	return {
		triggerUndeterminedValidation,
		triggerValidation,
		addValidation,
		clearValidation,
		clearAllValidations,
		parseError,
		validation
	}
}

export default useValidation
