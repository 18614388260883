/* eslint-disable react/display-name */
import React from "react"

import {
	sortableContainer,
	sortableElement,
	sortableHandle
} from "react-sortable-hoc"

import arrayMove from "array-move"

const SortableContainer = sortableContainer(({ children }) => <>{children}</>)

const DragHandle = sortableHandle(({ children }) => <>{children}</>)

const SortableSectionItem = sortableElement(({ children }) => <>{children}</>)

const handleSortStart = async (cursorType, onSortStart) => {
	document.body.style.cursor = cursorType ?? "default"

	if (onSortStart) onSortStart()
}

const handleSortEnd = async (arrayInfo, onChangeState, putValuesOrder) => {
	const { values, oldIndex, newIndex } = arrayInfo

	const currentValues = values.slice()

	document.body.style.cursor = "default"

	let valuesReordered = arrayMove(currentValues, oldIndex, newIndex)

	valuesReordered = valuesReordered.map((value, index) => ({
		...value,
		order: index
	}))

	await onChangeState(valuesReordered)

	await putValuesOrder(valuesReordered)
}

const Sortable = {}

Sortable.SectionContainer = (props) => {
	const {
		pressDelay,
		axis,
		lockAxis,
		useDragHandle,
		onSortStart,
		onSortEnd,
		children
	} = props

	return (
		<SortableContainer
			pressDelay={pressDelay}
			axis={axis}
			lockAxis={lockAxis}
			useDragHandle={useDragHandle}
			onSortStart={onSortStart}
			onSortEnd={onSortEnd}
			helperClass="SortableHelper"
		>
			{children}
		</SortableContainer>
	)
}

Sortable.DragHandle = (props) => {
	const { children } = props

	return <DragHandle>{children}</DragHandle>
}

Sortable.SortableSectionItem = (props) => {
	const { children, key, index, disabled } = props

	return (
		<SortableSectionItem key={key} index={index} disabled={disabled}>
			{children}
		</SortableSectionItem>
	)
}

Sortable.handleSortStart = (cursorType, onSortStart) => {
	handleSortStart(cursorType, onSortStart)
}

Sortable.handleSortEnd = (arrayInfo, onChangeState, putValuesOrder) => {
	handleSortEnd(arrayInfo, onChangeState, putValuesOrder)
}

export default Sortable
