import React from "react"
import { useSelector } from "react-redux"
import { invertHex } from "@/utils/colors"

const SidebarFooter = () => {
	const { sponsorConfig } = useSelector((state) => state.UserConfig)
	const backgroundColor = sponsorConfig.backgroundColor ?? "#393737"
	return (
		<>
			<div className="app-sidebar--footer">
				<footer
					style={{
						color: invertHex({
							colorHex: backgroundColor,
							lightColor: "#f1f1f1",
							darkColor: "#393737"
						})
					}}
				>
					@Copyright - EnemVip {new Date().getFullYear()}
				</footer>
			</div>
		</>
	)
}

export default SidebarFooter
