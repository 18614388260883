import { uppercaseLetter } from "@/utils/regex"

type DateValue = string | Date

export const formattedDateToNumericPT = (date: DateValue) => {
	if (!date) return null

	let convertDate = new Date(date).toISOString().substring(0, 10)

	convertDate = new Date(convertDate).toLocaleDateString("pt-BR", { timeZone: "UTC" })

	return convertDate
}

export const formattedDateToNumeric = (date: Date) => {
	if (!date) return null

	return new Date(date).toISOString().substring(0, 10)
}

export const toUppercaseFirstLetterByWord = (value: string) => {
	if (!value) return value

	let newValue = value?.toLowerCase()

	newValue = newValue
		.replace(uppercaseLetter, (x) => {
			return x.toUpperCase()
		})

	return newValue
}

export const formatEmail = (rawEmail: string): string => {
	let formattedEmail: string = rawEmail

	if (rawEmail) {
		formattedEmail = rawEmail
			?.toLowerCase()
			?.replace(/\s/g, "")
	}

	return formattedEmail
}

export const cleanObjectValues = (obj: { [string: string]: any }) => {
	for (const propName in obj) {
		if (obj[propName] === null || obj[propName] === undefined) {
			delete obj[propName]
		}
	}
	return obj
}
