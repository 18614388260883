import React, { lazy } from "react"
import { Route, Switch, useLocation } from "react-router-dom"

import { motion } from "framer-motion"
import { LeftSidebar } from "@/layouts/layout-blueprints"
import CompleteSignUpModal from "@/routes/private/components/CompleteSignUpModal"
import useDidMount from "@/hooks/useDidMount"
import { useCommonApi } from "@/services/api/commonApi"

const EditLandingPage = lazy(() => import("@/pages/LandingPage/Editor"))
const Dashboard = lazy(() => import("@/pages/Dashboard"))
const Simulate = lazy(() => import("@/pages/Users/Simulate"))
const MySimulates = lazy(() => import("@/pages/Users/MySimulates"))
const Classificatory = lazy(() => import("@/pages/Users/Classificatory"))
const ClassificatoryRank = lazy(() => import("@/pages/ClassificatoryRank"))
const ResultSimulate = lazy(() => import("@/pages/Users/ResultSimulate"))
const CreateOrEditSponsor = lazy(() => import("@/pages/Sponsor/CreateOrEdit"))
const ListSponsor = lazy(() => import("@/pages/Sponsor/List"))
const MyAccount = lazy(() => import("@/pages/MyAccount"))
const StudentConfig = lazy(() => import("@/pages/SponsorConfig"))
const CrmPage = lazy(() => import("@/pages/CRM"))
const CreateOrEditAccessCode = lazy(() => import("@/pages/AccessCode/CreateOrEdit"))
const ListAccessCode = lazy(() => import("@/pages/AccessCode/List"))
const Leads = lazy(() => import("@/pages/Leads"))
const WritingCourseAdmin = lazy(() => import("@/pages/WritingCourse/Admin"))
const CreateOrEditLessonCourseAdmin = lazy(() => import("@/pages/WritingCourse/Admin/CreateOrEdit"))
const WritingCourse = lazy(() => import("@/pages/WritingCourse"))
const LessonCourse = lazy(() => import("@/pages/WritingCourse/Lesson"))

const privatePageConfig = {
	pageTransition: {
		type: "tween",
		ease: "anticipate",
		duration: 0.4
	},
	pageVariants: {
		initial: {
			opacity: 0,
			scale: 0.99
		},
		in: {
			opacity: 1,
			scale: 1
		},
		out: {
			opacity: 0,
			scale: 1.01
		}
	}
}

const PrivateRoutes = () => {
	const location = useLocation()
	const pathsWithPageRaw = ["/editar-landing-page"]

	const commonApi = useCommonApi()

	useDidMount(() => {
		commonApi.loadUserData()
	})

	return (
		<>
			{
				pathsWithPageRaw.includes(location.pathname)
					? <Switch>
						<Route
							exact
							key="landing-page-edit"
							path="/editar-landing-page"
							component={EditLandingPage}
						/>
					</Switch>
					: (
						<LeftSidebar>
							<motion.div
								initial="initial"
								animate="in"
								exit="out"
								variants={privatePageConfig.pageVariants}
								transition={privatePageConfig.pageTransition}
							>
								<Switch>
									<Route
										exact
										path="/novo-simulado"
										component={Simulate}
									/>
									<Route
										exact
										path="/usuario-simulados"
										component={MySimulates}
									/>
									<Route
										exact
										path="/simulado-classificatorio"
										component={Classificatory}
									/>
									<Route
										exact
										path="/simulado-classificatorio/ranqueamento"
										component={ClassificatoryRank}
									/>
									<Route
										exact
										path="/resultado-simulado/:id"
										component={ResultSimulate}
									/>

									<Route
										exact
										path="/faculdade/criar"
										component={CreateOrEditSponsor}
									/>
									<Route
										exact
										path="/faculdade/editar/:sponsorId"
										component={CreateOrEditSponsor}
									/>
									<Route
										exact
										path="/faculdade/listar"
										component={ListSponsor}
									/>

									<Route
										exact
										path="/editar-modulo"
										component={StudentConfig}
									/>

									<Route
										exact
										path="/minha-conta"
										component={MyAccount}
									/>
									<Route
										exact
										path="/crm"
										component={CrmPage}
									/>

									<Route
										exact
										path="/codigo-acesso/criar"
										component={CreateOrEditAccessCode}
									/>
									<Route
										exact
										path="/codigo-acesso/editar/:accessCodeId"
										component={CreateOrEditAccessCode}
									/>
									<Route
										exact
										path="/codigo-acesso/listar"
										component={ListAccessCode}
									/>
									<Route
										exact
										path="/leads"
										component={Leads}
									/>

									<Route
										exact
										path="/curso-redacao-admin"
										component={WritingCourseAdmin}
									/>
									<Route
										exact
										path="/curso-redacao-admin/criar"
										component={CreateOrEditLessonCourseAdmin}
									/>
									<Route
										exact
										path="/curso-redacao-admin/editar/:id"
										component={CreateOrEditLessonCourseAdmin}
									/>

									<Route
										exact
										path="/curso-redacao"
										component={WritingCourse}
									/>

									<Route
										exact
										path="/curso-redacao/:id"
										component={LessonCourse}
									/>

									<Route
										exact
										path="/dashboard"
										component={Dashboard}
									/>
								</Switch>
							</motion.div>
						</LeftSidebar>
					)
			}

			<CompleteSignUpModal />
		</>
	)
}

export default PrivateRoutes
