/* eslint-disable */
export const regexCellPhone = /\(\d{2,}\)\d{4,5}\-\d{4}$/

export const regexCNPJAndCPF = /(^\d{3}\.\d{3}\.\d{3}\-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$)/

export const regexCEP = /(^\d{5}\-\d{3}$)/

export const regexDate = /^\d{1,2}\/\d{1,2}\/\d{4}$/

export const onlyNumberAndCommonLetter = /[^a-zA-Z0-9_]/g

export const removeSpaces = /\s/g

export const onlyLetter = /[^a-zA-Z ]/g

export const uppercaseLetter = /(\b[a-z](?!\s))/g

export const toCnpj = (val: string) => val.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')

export const videoLinkIsValid = /^https:\/\/(?:youtu)\.be(\/.*)?$/
