import React, { useState } from "react"
import {
	Button,
	CircularProgress,
	createStyles,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	InputAdornment,
	makeStyles,
	TextField,
	Tooltip,
	Typography
} from "@material-ui/core"
import {
	Close as CloseIcon,
	Help as HelpIcon
} from "@material-ui/icons"

// TODO - REMOVE FORMIK LIB (Use only api service)
import { useFormik } from "formik"

import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import GoogleLogin from "react-google-login"

import Api from "@/services/api"
import { pathName } from "@/services/history"
import { useCommonApi } from "@/services/api/commonApi"
import UserService from "@/services/UserService"

import { Divider, Notification } from "@/components"

import { facebook_auth_id, google_auth_id } from "@/config/socialMedia"
import { formatToOnlyNumberAndCommonLetter } from "@/utils/string"

import colors from "@/styles/colors"
import useCustomStyles from "@/styles/custom"

import GoogleIcon from "@/assets/images/icons/google-icon.svg"
import FacebookIcon from "@/assets/images/icons/facebook-icon.svg"

export type SocialMediaType = "facebook" | "google"

export type SocialMediaData = {
	type: SocialMediaType
	value: any
}

const useStyles = makeStyles(() =>
	createStyles({
		facebookBtn: {
			color: colors.grayScale[10],
			backgroundColor: "#486cb4",
			"&:hover": {
				backgroundColor: "#486cb4",
				opacity: 0.8
			}
		},
		googleBtn: {
			color: colors.grayScale[1],
			backgroundColor: "#ffffff"
		}
	})
)

const useSocialMedia = () => {
	const classes = useStyles()
	const customClasses = useCustomStyles()
	const commonApi = useCommonApi()

	const [openModal, setOpenModal] = useState(false)
	const [openChangeAccessCode, setOpenChangeAccessCode] = useState(false)
	const [socialMediaData, setSocialMediaData] = useState<SocialMediaData>(
		{} as SocialMediaData
	)
	const [accessCodeLoading, setAccessCodeLoading] = useState<boolean>(false)

	const toggleModal = () => setOpenModal(!openModal)

	const {
		handleSubmit,
		setFieldValue,
		setFieldError,
		values,
		errors
	} = useFormik({
		initialValues: {
			accessCode: ""
		},
		onSubmit: async (values) => {
			const { type, value } = socialMediaData

			setAccessCodeLoading(true)

			if (openChangeAccessCode) {
				await Api.put("account/change-access-code", {
					socialMedia: type,
					socialMediaData: value,
					accessCode: values.accessCode,
					pathUrl: pathName[1]
				})
					.then(async ({ data }) => {
						UserService.login(data)
						await commonApi.loadUserData()
					})
					.catch((e) => {
						const { message } = e.response.data

						Notification.error({
							message
						})
					})
			} else {
				await Api.post(`/account/signin/social-media/${type}`, {
					accessCode: values.accessCode,
					value,
					pathUrl: pathName[1]
				})
					.then(({ data }) => {
						UserService.login(data.token)
						commonApi.loadUserData()
					})
					.catch((e) => {
						const { message } = e.response.data

						setFieldError("accessCode", message)

						Notification.error({
							message
						})
					})
			}

			setAccessCodeLoading(false)
		}
	})

	const handleChangeCodeAccess = (value: string) => {
	// Change spaces to => _ (Underline)
		let formattedAccessCode = value.replace(/\s/g, "_")

		formattedAccessCode = formatToOnlyNumberAndCommonLetter(
			formattedAccessCode.toUpperCase()
		)

		setFieldValue("accessCode", formattedAccessCode)
	}

	const toggleModalAccessCode = () => {
		if (openChangeAccessCode) {
			setFieldValue("accessCode", "")
		}

		setOpenChangeAccessCode(!openChangeAccessCode)
	}

	const ModalWarning = () => (
		<Dialog
			open={openModal}
			onClose={toggleModal}
			PaperProps={{
				className: customClasses.paperDialog
			}}
			maxWidth="sm"
			fullWidth
		>
			<DialogTitle>
				<Typography align="center" variant="h3" color="primary">
				Insira o código de acesso para continuar
				</Typography>
			</DialogTitle>

			<DialogContent>
				<Grid
					container
					justify="center"
					component="form"
					spacing={2}
					onSubmit={handleSubmit}
				>
					<Grid item xs={12}>
						<TextField
							tabIndex={1}
							name="accessCode"
							label="Código de acesso"
							placeholder="Digite o código fornecido pela instituição"
							type="text"
							onChange={({ target }) => handleChangeCodeAccess(target.value)}
							fullWidth
							variant="outlined"
							value={values.accessCode}
							helperText={errors.accessCode}
							error={Boolean(errors.accessCode)}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<Tooltip
											title="Código informado pela instituição de ensino."
										>
											<HelpIcon/>
										</Tooltip>
									</InputAdornment>
								)
							}}
						/>
					</Grid>

					<Grid item xs={12}>
						<Grid container justify="center" alignItems="center" spacing={2}>
							<Grid item>
								<Button
									tabIndex={3}
									onClick={toggleModal}
								>
									Fechar
								</Button>
							</Grid>

							<Grid item>
								<Button
									type="submit"
									tabIndex={2}
									color="primary"
									variant="contained"
									disabled={accessCodeLoading}
									endIcon={
										accessCodeLoading &&
											<CircularProgress size={16} color="inherit"/>
									}
								>
									Validar código
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

			</DialogContent>
		</Dialog>
	)

	const ModalChangeAccessCode = () => (
		<Dialog
			open={openChangeAccessCode}
			onClose={toggleModalAccessCode}
			PaperProps={{
				className: customClasses.paperDialog
			}}
			maxWidth="sm"
			fullWidth
		>
			<IconButton onClick={toggleModalAccessCode} className={customClasses.closeIcon}>
				<CloseIcon />
			</IconButton>

			<DialogTitle>
				<Typography align="center" variant="h3" color="primary">
					Insira um novo código de acesso!
				</Typography>
			</DialogTitle>

			<Divider size={2} orientation="horizontal" />

			<DialogContent>
				<Typography align="center" variant="body2">
					O código que estava utilizando foi deletado, cadastre um novo...
				</Typography>

				<Divider size={2} orientation="horizontal" />

				<Grid
					container
					justify="center"
					alignContent="center"
					component="form"
					spacing={2}
					onSubmit={handleSubmit}
				>
					<Grid item xs={12}>
						<TextField
							tabIndex={1}
							name="newAccessCode"
							label="Código de acesso"
							placeholder="Digite o código fornecido pela instituição"
							type="text"
							onChange={({ target }) => handleChangeCodeAccess(
								target.value
							)}
							value={values.accessCode}
							variant="outlined"
							fullWidth
							helperText={errors.accessCode}
							error={Boolean(errors.accessCode)}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<Tooltip
											title="Código informado pela instituição de ensino."
										>
											<HelpIcon/>
										</Tooltip>
									</InputAdornment>
								)
							}}
						/>
					</Grid>

					<Grid item xs={12}>
						<Grid container justify="center" spacing={2}>
							<Grid item>
								<Button
									tabIndex={3}
									onClick={toggleModalAccessCode}
								>
									Fechar
								</Button>
							</Grid>

							<Grid item>
								<Button
									type="submit"
									tabIndex={2}
									color="primary"
									variant="contained"
									disabled={accessCodeLoading}
									endIcon={
										accessCodeLoading &&
											<CircularProgress size={16} color="inherit"/>
									}
								>
										Validar
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

			</DialogContent>

		</Dialog>
	)

	const loginSocialMedia = async (type: SocialMediaType, data: any) => {
		await Api.post(`/account/signin/social-media/${type}`, {
			data,
			pathUrl: pathName[1]
		})
			.then(({ data }) => {
				if (data?.message === "NotUser") {
					setSocialMediaData({
						type: data.socialMedia,
						value: data.socialMediaData
					})
					toggleModal()
				} else if (data?.error === "CodeError") {
					setSocialMediaData({
						type: data.user.socialMedia,
						value: data.user.socialMediaData
					})
					toggleModalAccessCode()

					Notification.error({
						message: data.message
					})
				} else {
					UserService.login(data.token)
					commonApi.loadUserData()
				}
			})
			.catch((e) => {
				Notification.error({
					message: "Um erro inesperado ocorreu..."
				})
			})
	}

	const FacebookAuth = () => {
		return (
			<>
				<FacebookLogin
					appId={String(facebook_auth_id)}
					fields="name,email,picture"
					callback={(data: unknown) =>
						loginSocialMedia("facebook", data as unknown)
					}
					render={(renderProps: any) => (
						<Button
							id="button-facebook-auth"
							fullWidth
							color="inherit"
							startIcon={
								<img src={FacebookIcon} alt="Facebook - Login"/>
							}
							variant="contained"
							className={classes.facebookBtn}
							{...renderProps}
						>
						Entrar com o Facebook
						</Button>
					)}
				/>
				{ModalChangeAccessCode()}
				{ModalWarning()}
			</>
		)
	}

	const GoogleAuth = () => {
		return (
			<>
				<GoogleLogin
					clientId={String(google_auth_id)}
					render={(renderProps) => (
						<Button
							id="button-facebook-auth"
							fullWidth
							color="inherit"
							startIcon={
								<img src={GoogleIcon} alt="Google - Login"/>
							}
							variant="outlined"
							className={classes.googleBtn}
							disabled={renderProps.disabled}
							onClick={() => renderProps.onClick()}
						>
						Entrar com o Google
						</Button>
					)}
					buttonText="Login"
					onSuccess={(data) =>
						loginSocialMedia("google", data as unknown)
					}
					onFailure={(data) =>
						loginSocialMedia("google", data as unknown)
					}
					cookiePolicy={"single_host_origin"}
				/>

				{ModalChangeAccessCode()}
				{ModalWarning()}
			</>
		)
	}

	return {
		FacebookAuth,
		GoogleAuth
	}
}

export default useSocialMedia
