import React from "react"
import ReactDOM from "react-dom"

import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"

import App from "@/App"
import { BugsnagConfig } from "@/config/Bugsnag"

import * as serviceWorker from "./serviceWorker"

Bugsnag.start({
	apiKey: BugsnagConfig.apiKey,
	plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = (Bugsnag.getPlugin("react") as any)
	.createErrorBoundary(React)

ReactDOM.render(
	<ErrorBoundary>
		<App />
	</ErrorBoundary>
	, document.getElementById("root")
)

serviceWorker.unregister()
