export const Types = {
	SET_USER_INFO: "USER_DATA/SET_USER_INFO",
	SET_PHOTO_URL: "USER_DATA/SET_PHOTO_URL",
	SET_APP_CONFIG: "USER_DATA/SET_APP_CONFIG"
}

export const setUserInfo = (userInfo) => ({
	type: Types.SET_USER_INFO,
	userInfo
})

export const setSponsorConfig = (sponsorConfig) => ({
	type: Types.SET_APP_CONFIG,
	sponsorConfig
})

export const setPhotoUser = (photoUrl) => ({
	type: Types.SET_PHOTO_URL,
	photoUrl
})

export default function reducer (
	state = {
		userInfo: {
			backgroundColor: "#393737",
			additionalInfo: {
				requiredInfoIsOk: null,
				optionalInfoIsOk: null
			}
		},
		sponsorConfig: {
			backgroundColor: "#393737",
			additionalInfo: {
				requiredInfoIsOk: null,
				optionalInfoIsOk: null
			}
		}
	},
	action
) {
	switch (action.type) {
	case Types.SET_USER_INFO:
		return {
			...state,
			userInfo: {
				...state.userInfo,
				...action.userInfo
			}
		}
		case Types.SET_PHOTO_URL:
		return {
			...state,
			userInfo: {
				...state.userInfo,
				photoUrl: action.photoUrl
			}
		}
		case Types.SET_APP_CONFIG:
		return {
			...state,
			sponsorConfig: {
				...state.sponsorConfig,
				...action.sponsorConfig
			}
		}
		default:
		break
	}
	return state
}
