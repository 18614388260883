import React, { useEffect, useState } from "react";
import {
	Box,
	Container,
	Grid,
	Typography,
	Button,
	Tooltip,
	Link,
} from "@material-ui/core";
import {
	AttachMoney as MoneyIcon,
	Done as DoneIcon,
	MenuBook as EnemIcon,
	AccessTime as TimeIcon,
	Assessment as AssessmentIcon,
	Create as CreateIcon,
	Facebook as FacebookIcon,
	Language as WebsiteIcon,
	Instagram as InstagramIcon,
	Phone as PhoneIcon,
	Email as EmailIcon,
} from "@material-ui/icons";

import { useLocation } from "react-router-dom";

import history, { pathName } from "@/services/history";
import { Divider, SuspenseLoading } from "@/components";

import useStyles, {
	DemoPlatformContainer,
	FooterContainer,
	HeaderContainer,
	HeroContainer,
	LandingPageContainer,
	PlacardContainer,
} from "./styles";
import useDidMount from "@/hooks/useDidMount";

import bedukaLogoImg from "@/assets/images/logo/enemvip-logo.svg";
import bedukaBannerImg from "@/assets/images/background/background_default.svg";
import { formatPhoneNumber } from "@/utils/mask";

type ItemIntroProps = Array<{
	title: string;
	description: string;
	icon: React.ReactNode;
}>;

export type LandingDataProps = {
	trademarkUrlLanding?: string;
	bannerLanding?: string;

	titleLanding?: string;
	titleLandingColor?: string;

	subtitleLanding?: string;
	subtitleLandingColor?: string;

	btnLanding?: string;
	btnLandingColor?: string;

	footerLandingColor?: string;

	imageDemoLanding?: string;
	linkFacebookLanding?: string;
	linkInstagramLanding?: string;
	linkSiteLanding?: string;
	emailLanding?: string;
	telephoneLanding?: string;
	baseUrlLanding?: string;
};

type LandingPageProps = {
	onLoad?: (pathUrl: string) => Promise<LandingDataProps>;
	dataLanding: LandingDataProps;
};

const LandingPage: React.FC<LandingPageProps> = (props) => {
	const { onLoad, dataLanding } = props;

	const [landingData, setLandingData] = useState<LandingDataProps>(
		{} as LandingDataProps
	);

	const location = useLocation();
	const classes = useStyles({
		btnLandingColor: landingData?.btnLandingColor,
		footerLandingColor: landingData?.footerLandingColor || "#191919",
	});

	const isIndexPage = !location.pathname.includes("editar-landing-page");
	const currentYear = new Date().getFullYear();

	const onLoadLanding = async () => {
		let landingData = {};

		if (!pathName[1] && onLoad) {
			landingData = await onLoad("enem-vip");
		}

		if (isIndexPage && onLoad) {
			landingData = await onLoad(pathName[1]);
		}

		setLandingData(landingData);
	};

	const onChangeLanding = () => {
		setLandingData(dataLanding);
	};

	const handleChangePage = () => {
		history.push("/signup");
	};

	const WrapperItemIntroComponent = () => {
		const itemsIntro: ItemIntroProps = [
			{
				title: "100% online e gratuito",
				description:
					"Estude e faça simulados ENEM onde e quando quiser, de forma totalmente gratuita.",
				icon: <MoneyIcon className={classes.iconItemIntro} />,
			},
			{
				title: "Sem limitações",
				description:
					"Treine antes da prova quantas vezes quiser, sem limitações. O simulado não sai do ar e você pode sempre voltar a treinar",
				icon: <DoneIcon className={classes.iconItemIntro} />,
			},
			{
				title: "Questões do ENEM",
				description:
					"As nossas questões são extraídas das provas anteriores do ENEM, assim você treina exatamente o que precisa.",
				icon: <EnemIcon className={classes.iconItemIntro} />,
			},
			{
				title: "Resultado na hora",
				description:
					"O resultado sai na hora com uma análise completa do seu desempenho. Você pode também consultar seus simulados anteriores para ver a evolução.",
				icon: <TimeIcon className={classes.iconItemIntro} />,
			},
			{
				title: "100% online e gratuito",
				description:
					"A nota segue o modelo TRI (Teoria de Resposta ao Item) para você ter uma base de qual seria sua nota no ENEM.",
				icon: <AssessmentIcon className={classes.iconItemIntro} />,
			},
			{
				title: "Curso de Redação",
				description:
					'Curso completo de redação: "As técnicas da redação perfeita reveladas - Guia para nota 1000 no ENEM".',
				icon: <CreateIcon className={classes.iconItemIntro} />,
			},
		];

		return (
			<Grid
				container
				justify="space-between"
				alignContent="center"
				spacing={3}
			>
				{itemsIntro.map((item, index) => (
					<Grid item xs={12} sm={6} md={4} key={index}>
						<Box component="span" className={classes.boxIconIntro}>
							{item.icon}
						</Box>

						<Divider size={4} orientation="horizontal" />

						<Typography
							variant="h4"
							align="center"
							className={classes.titleItemIntro}
						>
							{item.title}
						</Typography>

						<Divider size={2} orientation="horizontal" />

						<Typography
							variant="body1"
							align="center"
							className={classes.descriptionItemIntro}
						>
							{item.description}
						</Typography>

						<Divider size={2} orientation="horizontal" />
					</Grid>
				))}
			</Grid>
		);
	};

	useDidMount(() => {
		onLoadLanding();
	});

	useEffect(() => {
		onChangeLanding();
		// eslint-disable-next-line
	}, [dataLanding]);

	if (!landingData) return <SuspenseLoading />;

	return (
		<LandingPageContainer
			isIndexPage
			btnColor={landingData?.btnLandingColor}
		>
			<HeroContainer
				bannerTop={landingData?.bannerLanding || bedukaBannerImg}
			>
				<HeaderContainer className="section-container">
					<div className="section-content header-content">
						<div>
							<img
								src={
									landingData?.trademarkUrlLanding ||
									bedukaLogoImg
								}
								className="logo-header"
								alt="Logo Landing Page"
							/>
						</div>
						{pathName[1] !== "enem-vip" && (
							<div className="fr-100-horizontal">
								<Button
									color="primary"
									variant="contained"
									onClick={handleChangePage}
									className={classes.buttonHeader}
								>
									{landingData?.btnLanding || "Entrar"}
								</Button>
							</div>
						)}
					</div>
				</HeaderContainer>

				<Divider size={2} orientation="horizontal" />

				<PlacardContainer
					className="section-container"
					titleColor={landingData?.titleLandingColor}
					subTitleColor={landingData?.subtitleLandingColor}
				>
					<div className="section-content placardText-content">
						{landingData?.titleLanding && (
							<h1 className="mainTitle-plancard">
								{landingData?.titleLanding}
							</h1>
						)}
						{landingData?.subtitleLanding && (
							<p className="subtitle-plancard">
								{landingData?.subtitleLanding}
							</p>
						)}
						<Divider size={2} orientation="horizontal" />
					</div>
				</PlacardContainer>
			</HeroContainer>

			{landingData?.imageDemoLanding && (
				<DemoPlatformContainer className="section-container">
					<div className="section-content demoPlatform-content">
						<div className="imageExample-wrapper-demoPlatform ">
							<img
								className="imageExample-item-demoPlatform"
								alt="Imagem central"
								src={landingData?.imageDemoLanding}
							/>
						</div>
					</div>
				</DemoPlatformContainer>
			)}

			<Divider
				size={landingData?.imageDemoLanding ? 14 : 8}
				orientation="horizontal"
			/>

			<Box component="section">
				<Container maxWidth="md">
					<Typography
						align="center"
						variant="h2"
						className={classes.titleIntro}
					>
						OS BENEFÍCIOS DO NOSSO SIMULADO ENEM
					</Typography>

					<Divider size={3} orientation="horizontal" />

					<Typography
						align="center"
						variant="body1"
						className={classes.subtitleIntro}
					>
						São diversas as vantagens em utilizar o nosso{" "}
						<strong>SIMULADO + CURSO DE REDAÇÃO</strong> para se
						preparar para o <strong>ENEM</strong>. Estudando e
						fazendo os nossos exercícios, você certamente irá mandar
						muito bem no ENEM e conquistar sua vaga na faculdade.
					</Typography>
				</Container>

				<Divider size={6} orientation="horizontal" />

				<Container maxWidth="lg">
					{WrapperItemIntroComponent()}
				</Container>
			</Box>

			<Divider size={8} orientation="horizontal" />

			<FooterContainer
				className="section-container"
				backgroundColor={landingData?.footerLandingColor || "#191919"}
			>
				<div className="section-content">
					<div className="infos-content-footerContainer">
						{landingData?.trademarkUrlLanding && (
							<>
								<Divider size={3} orientation="horizontal" />

								<img
									src={landingData?.trademarkUrlLanding}
									className="logo-footer"
									alt="Logo Temporária"
								/>

								<Divider size={3} orientation="horizontal" />

								<div className="divider border-1 d-none d-md-block rounded-circle border-divider-footer opacity-2 mx-auto my-3 w-25" />
							</>
						)}

						<Grid container spacing={3} justify="center">
							{landingData?.linkFacebookLanding && (
								<Grid item>
									<Tooltip title="Facebook">
										<Link
											component="a"
											className={
												classes.socialMediaFooter
											}
											href={`https://www.facebook.com/${landingData?.linkFacebookLanding}`}
											rel="nofollow"
											target="_blank"
											id="btn-socialMedia-facebook"
										>
											<FacebookIcon />
										</Link>
									</Tooltip>
								</Grid>
							)}

							{landingData?.linkInstagramLanding && (
								<Grid item>
									<Tooltip title="Instagram">
										<Link
											component="a"
											className={
												classes.socialMediaFooter
											}
											href={`https://www.instagram.com/${landingData?.linkInstagramLanding}`}
											rel="nofollow"
											target="_blank"
											id="btn-socialMedia-instagram"
										>
											<InstagramIcon />
										</Link>
									</Tooltip>
								</Grid>
							)}

							{landingData?.linkSiteLanding && (
								<Grid item>
									<Tooltip title="Site">
										<Link
											component="a"
											className={
												classes.socialMediaFooter
											}
											href={landingData?.linkSiteLanding}
											rel="nofollow"
											target="_blank"
											id="btn-socialMedia-website"
										>
											<WebsiteIcon />
										</Link>
									</Tooltip>
								</Grid>
							)}
						</Grid>
					</div>
				</div>

				<div className="contact-wrapper-footer">
					<ul>
						{landingData?.emailLanding && (
							<li>
								<Link
									component="a"
									underline="none"
									href={`mailto:${landingData.emailLanding}`}
									target="_blank"
									rel="noreferrer"
								>
									<EmailIcon /> {landingData.emailLanding}
								</Link>
							</li>
						)}

						{landingData?.telephoneLanding && (
							<li>
								<Link
									component="a"
									underline="none"
									href={`tel:${landingData.telephoneLanding}`}
									target="_blank"
									rel="noreferrer"
								>
									<PhoneIcon />{" "}
									{formatPhoneNumber(
										landingData.telephoneLanding
									)}
								</Link>
							</li>
						)}
					</ul>
				</div>

				<p>
					<small className="copyright-footer text-center d-block">
						Copyright &copy; {currentYear} -
						<a
							className="text-copyright"
							href="https://beduka.com.br"
							title="Beduka"
							target="_blank"
							rel="noopener noreferrer"
						>
							Enem Vip
						</a>
					</small>
				</p>
				<Divider size={1} orientation="horizontal" />
			</FooterContainer>
		</LandingPageContainer>
	);
};

export default LandingPage;
