const colors = {
	palette: {
		primary: "#3B4699",

		confirm: "#1AB956",
		error: "#D84910",
		warning: "#F1C34E",
		info: "#188FFB",

		link: "#0b1880",
		background: "#F8F9FA",
		button1: "#03DAC5"
	},
	grayScale: {
		1: "#212429",
		2: "#DDE2E5",
		3: "#ACB5BD",
		4: "#495057",
		5: "#969696",
		6: "#ABABAB",
		7: "#BFBFBF",
		8: "#D0D0D0",
		9: "#E1E1E1",
		10: "#F0F0F0",
		11: "#FFFFFF"
	}
}

export default colors
