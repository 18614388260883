import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid } from "@material-ui/core"

import { Portlet } from "@/components"

const GenerateSheetsSkeleton = () => {
	return (
		<Grid
			container
			spacing={2}
			justify="space-between"
		>
			<Grid
				item
				xs={12}
				sm={6}
			>
				<Portlet
					elevation={3}
				>
					<Grid container justify="space-between" spacing={2}>
						<Grid item xs={12} md={6}>
							<Skeleton
								variant="rect"
								width="100%"
								height={180}
								animation="wave"
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<Skeleton
								variant="rect"
								width="100%"
								height={180}
								animation="wave"
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<Skeleton
								variant="rect"
								width="100%"
								height={180}
								animation="wave"
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<Skeleton
								variant="rect"
								width="100%"
								height={180}
								animation="wave"
							/>
						</Grid>

					</Grid>

				</Portlet>

			</Grid>

			<Grid
				item
				xs={12}
				sm={6}
			>
				<Portlet
					elevation={3}
				>
					<Grid container justify="center" spacing={2}>
						<Grid item xs={12}>
							<Skeleton
								variant="rect"
								width="100%"
								height={45}
								animation="wave"
							/>
						</Grid>

						<Grid item xs={12}>
							<Skeleton
								variant="rect"
								width="100%"
								height={120}
								animation="wave"
							/>
						</Grid>
					</Grid>

				</Portlet>
			</Grid>
		</Grid>

	)
}

export default GenerateSheetsSkeleton
