import React, { useState } from "react"
import { Link } from "react-router-dom"

import {
	Button,
	Grid,
	Link as LinkMui,
	TextField,
	CircularProgress,
	Typography
} from "@material-ui/core"

import { Divider, Notification } from "@/components"
import ApiService from "@/services/api"
import history, { pathName } from "@/services/history"

import { formatEmail } from "@/utils/sanitization"
import useValidation from "@/hooks/useValidation"

import CommonAuthStudent from "@/pages/Auth/components/Container"

type RequestDataProps = {
	email: string
}

const RequestPassword = () => {
	const {
		validation,
		clearValidation,
		triggerValidation
	} = useValidation()

	const [requestData, setRequestData] = useState<RequestDataProps>({ email: "" })
	const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)

	const submitRequestData = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		setLoadingSubmit(true)

		try {
			await ApiService.post("/auth/forgot-password/request", {
				email: requestData.email,
				pathUrl: pathName[1]
			})

			Notification.success({
				message: "Email de recuperação enviado!",
				onClose: () => history.push("/")
			})
		} catch (error) {
			triggerValidation(error)
		}

		setLoadingSubmit(false)
	}

	const handleRequestDataChange = (type: keyof RequestDataProps, value: string) => {
		clearValidation(type)

		setRequestData((currentState) => ({
			...currentState,
			[type]: value
		}))
	}

	return (
		<CommonAuthStudent>
			<Grid
				spacing={1}
				container
				justify="center"
				component="form"
				onSubmit={submitRequestData}
			>
				<Grid item xs={10}>
					<TextField
						id="input-request-password-email"
						label="Email"
						tabIndex={1}
						placeholder="seunome@seuemail.com"
						name="email"
						value={requestData.email}
						onChange={({ target }) =>
							handleRequestDataChange(
								"email",
								formatEmail(target.value)
							)
						}
						variant="outlined"
						fullWidth
						helperText={validation.email}
						error={Boolean(validation.email)}
					/>
				</Grid>

				<Divider size={1} orientation="horizontal" />

				<Grid item xs={10}>
					<Button
						tabIndex={-1}
						color="primary"
						type="submit"
						variant="contained"
						endIcon={
							loadingSubmit &&
										<CircularProgress size={16} color="inherit"/>
						}
						disabled={loadingSubmit}
						fullWidth
					>
									Recuperar senha
					</Button>
				</Grid>

				<Divider size={1.5} orientation="horizontal" />

				<Grid item xs={12}>
					<Typography
						align="center"
					>
						<LinkMui
							component={Link}
							to="/"
							color="inherit"
							underline="none"
						>
										Voltar para página inicial
						</LinkMui>
					</Typography>
				</Grid>
			</Grid>
		</CommonAuthStudent>
	)
}

export default RequestPassword
