import React, { Fragment, Component } from "react"

import clsx from "clsx"
import { Link } from "react-router-dom"
import { AvTimer as AvTimerIcon } from "@material-ui/icons"

import { connect } from "react-redux"

class PageTitle extends Component {
	constructor (props) {
		super(props)
		this.state = {
			modal: false
		}

		this.toggleModal = this.toggleModal.bind(this)
	}

	toggleModal () {
		this.setState({
			modal: !this.state.modal
		})
	}

	render () {
		const {
			pageTitleStyle,
			pageTitleBackground,
			pageTitleShadow,
			pageTitleBreadcrumb,
			pageTitleIconBox,
			icon
		} = this.props
		return (
			<Fragment>
				<div
					className={clsx(
						"app-page-title",
						pageTitleStyle,
						pageTitleBackground,
						{
							"app-page-title--shadow": pageTitleShadow
						}
					)}
				>
					<div>
						{pageTitleBreadcrumb && (
							<ol className="app-page-title--breadcrumb breadcrumb">
								<li className="breadcrumb-item">
									<Link to="/">
										<AvTimerIcon/>
									</Link>
								</li>
								<li className="breadcrumb-item">
									<Link to="/dashboard">Dashboards</Link>
								</li>
								<li className="breadcrumb-item active">
									<span>{this.props.titleHeading}</span>
								</li>
							</ol>
						)}

						<div className="app-page-title--first">
							{pageTitleIconBox && (
								<div className="app-page-title--iconbox d-70">
									<div className="d-70 d-flex gradient-icon align-items-center justify-content-center">
										<i
											className={`pe-7s-umbrella ${icon} display-3 gradient-icon bg-ripe-malin`}
										></i>
									</div>
								</div>
							)}
							<div className="app-page-title--heading">
								<h1>{this.props.titleHeading}</h1>
								{this.props.titleDescription && (
									<div className="app-page-title--description">
										{this.props.titleDescription}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	pageTitleStyle: state.ThemeOptions.pageTitleStyle,
	pageTitleBackground: state.ThemeOptions.pageTitleBackground,
	pageTitleShadow: state.ThemeOptions.pageTitleShadow,
	pageTitleBreadcrumb: state.ThemeOptions.pageTitleBreadcrumb,
	pageTitleIconBox: state.ThemeOptions.pageTitleIconBox
})

export default connect(mapStateToProps)(PageTitle)
